<template>

  <div class="godownEntryListDetails">
    <div class="headerBox">
      <div  class="headerBox_operate">
        <template v-if="orderDetail.entry_status_id!=20">
             <el-button type="primary" icon="el-icon-plus" size="mini" @click="parentMethod(1)" v-if='AuthpermissionsData[setPermissionsData.createDetail.toLowerCase()]||AuthpermissionsData[setPermissionsData.editEntry.toLowerCase()]'>保存</el-button>
             <el-button  icon="el-icon-folder-add" size="mini" @click="parentMethod(2)" v-if='AuthpermissionsData[setPermissionsData.createDetail.toLowerCase()]||AuthpermissionsData[setPermissionsData.editEntry.toLowerCase()]'>保存并新增</el-button>
        </template>
        <template v-else>
              <el-button type="primary" icon="el-icon-plus" size="mini" @click="parentMethod(14)" v-if='AuthpermissionsData[setPermissionsData.createDetail.toLowerCase()]||AuthpermissionsData[setPermissionsData.editEntry.toLowerCase()]'>新建</el-button>
        </template>

        <el-button size="mini" icon='el-icon-check' v-if="orderDetail.entry_status_id==10" @click="parentMethod(3)">审核</el-button>
        <el-button size="mini" style="margin-right:10px;" icon='el-icon-check' v-if="orderDetail.entry_status_id==20&&AuthpermissionsData[setPermissionsData.reverseCheckEntry.toLowerCase()]" @click="parentMethod(13)">反审核</el-button>
        <!-- <el-button  -button size="mini" icon="el-icon-bottom" @click="parentMethod(4)">导入</el-button> -->
            <el-dropdown v-if="orderDetail.entry_status_id!=20" >

                  <div >
                    <el-upload v-if="AuthpermissionsData[setPermissionsData.importGoodsDataByUploadFile.toLowerCase()]"
                    :action="
                      baseUrl + '/store/goods.EntryCtl/importGoodsDataByUploadFile'
                    "
                    :data="{
                      goods_type_id:detailQuery.goods_type_id,
                      goods_number_type:detailQuery.goods_number_type,
                      getFileTitle: 1,
                    }"
                    name="datafile"
                    :headers="{
                      authorization: token,
                    }"
                    :show-file-list="false"
                    :on-success="handleGoodsSuccess"
                    style="
                      margin:0 10px;
                      display:inline-block;
                    ">
                    <el-button size="mini" >
                      导入
                    </el-button>
                 </el-upload>
                </div>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="parentMethod(12)" v-if="AuthpermissionsData[setPermissionsData.uploadGoodsPicByZip.toLowerCase()]">导入货品图片</el-dropdown-item>
                <el-dropdown-item @click.native="parentMethod(11)" v-if="AuthpermissionsData[setPermissionsData.getImportFile.toLowerCase()]">下载导入模板</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        <el-button size="mini" icon="el-icon-top" @click="parentMethod(8)" v-if="AuthpermissionsData[setPermissionsData.batchExportEntryGoods.toLowerCase()]">导出</el-button>
        <!-- <el-button icon="el-icon-printer" size="mini" @click="parentMethod(5)">打印</el-button> -->

          <el-dropdown v-if="AuthpermissionsData[setPermissionsData.entryPrintDetail.toLowerCase()]">
            <el-button size="mini" style="margin:0 10px 0 10px;" @click.native="parentMethod(5)">
              打印
              <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item @click.native="parentMethod(5)">打印</el-dropdown-item> -->
              <el-dropdown-item @click.native="parentMethod(9)">打印设置</el-dropdown-item>
              <el-dropdown-item @click.native="parentMethod(10)">打印标签</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        <el-button icon="el-icon-s-fold" size="mini" @click="parentMethod(6)">单据列表</el-button>
        <el-button type="danger" icon="el-icon-close" size="mini" @click="parentMethod(7)" >关闭</el-button>
      </div>
    </div>
     <div class="mian_top" ref="top">
      <div class="headerBox">
        <div class="headerBox_title">
          <span>基础信息</span>
          <div class="headerBox_right" @click="setInfo" v-if='AuthpermissionsData[setPermissionsData.setConfig.toLowerCase()]'>
            <i class='el-icon-setting' ></i>

            <span>设置</span>
          </div>
        </div>
      </div>
      <basisInfo ref="basisInfoChild" @getSelect="getSelect(arguments)" @receiveFromInfo="receiveFromInfo"  :setData="setData"  @tabstyle="tabstyle" />
    </div>

    <!-- 入库详情表格 -->
    <div class="mian_tab" :style="mian_tabStyle">
       <editTable ref="child" class="editTable" :detailQuery="detailQuery"  :style="editTable" :editTableHeight="editTableHeight" :setPermissionsData="setPermissionsData" :AuthpermissionsData="AuthpermissionsData" />
    </div>

       <el-dialog
      :visible.sync="templateVisiable"
      title="选择对应表头模板"
      width="30%"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :before-close="cancelTemplateTable"
    >
      <div>
        <el-table :data="templateList" stripe style="border: 1px solid #e8e8e8">
          <el-table-column align="center" prop="template_name" label="模板名称">
          </el-table-column>
          <el-table-column align="center" prop="action" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="handleChooseTemplate(scope.row)"
                >选择</el-button
              >
              <el-button type="text" @click="handleDelTemplate(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagina">
          <el-pagination
            background
            layout="total, prev, pager, next, jumper"
            :total="templateTotal"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
        <div style="text-align: center; margin-top: 15px">
          <el-button type="primary" plain @click="handelAddTemplate"
            >新增表头模板</el-button
          >
        </div>
      </div>
    </el-dialog>


    <el-dialog
      :visible.sync="tableVisiable"
      :title="templateName"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :before-close="cancelTable"
    >
      <div v-if="templateName === '新增表头模板'">
        <el-form @submit.native.prevent>
          <el-form-item label="模板名称" label-width="80px" required>
            <el-input
              v-model="template_name"
              autocomplete="off"
              placeholder="请输入模板名称"
              style="width: 80%"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="display: flex; margin: 0 auto; width: 100%">
        <div style="margin-right: 10px; width: 50%">
          <div class="title">
            <div class="span"></div>
            系统表头
          </div>
          <div
            style="
              border-left: 1px solid #e8e8e8;
              border-right: 1px solid #e8e8e8;
              border-top: 1px solid #e8e8e8;
            "
          >
            <el-table :data="fieldList" stripe>
              <el-table-column align="center" prop="field" label="表头字段">
                <template slot-scope="scope">
                  <span style="color: red">{{
                    scope.row.is_mandatory === 20 ? "*" : ""
                  }}</span
                  >{{ scope.row.field_text }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div style="width: 50%">
          <div class="title">
            <div class="span"></div>
            设置文件表头
          </div>
          <div
            style="
              border-left: 1px solid #e8e8e8;
              border-right: 1px solid #e8e8e8;
              border-top: 1px solid #e8e8e8;
            "
          >
            <el-table :data="fieldList" stripe>
              <el-table-column align="center" label="表头字段">
                <template slot-scope="scope">
                  <el-select
                    v-model="goodsinfo_map[fieldList[scope.$index].field]"
                    @change="handleDisableFileList"
                    style="width: 100%"
                    placeholder="请选择"
                    clearable
                  >
                    <el-option
                      v-for="items in downFiledList"
                      :label="items.name"
                      :value="items.name"
                      :key="items.name"
                    >
                    </el-option
                  ></el-select>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div style="text-align: center; margin-top: 15px">
        <el-button
          type="primary"
          :loading="loading"
          @click="handleConfigTemplate(10)"
          v-if="templateName === '新增表头模板'"
          >保存</el-button
        >
        <el-button
          type="primary"
          :loading="loading"
          @click="handleConfigTemplate(20)"
          v-if="templateName === '新增表头模板'"
          >保存并使用模板</el-button
        >
        <el-button
          type="primary"
          @click="handleSureTable(10)"
          :loading="loading"
          v-if="templateName !== '新增表头模板'"
          >使用</el-button
        >
        <el-button
          type="primary"
          @click="handleSureTable(20)"
          :loading="loading"
          v-if="templateName !== '新增表头模板'"
          >使用并保存模板</el-button
        >
      </div>
    </el-dialog>


  </div>
</template>

<script>
import storage from "good-storage";
import Cookies from "js-cookie";
import editTable from "./components/editTable.vue";
import basisInfo from "@/components/form/basisInfo.vue";
import {getMyAuth} from "@/api/authority/config";
import {permissionsData} from "@/api/authority/permissions";
import {getDefaultFormValue} from "@/api/components/index.js";
import {
  templateList,
  getTemplateInfo,
  addTemplateInfo,
  reverseCheckEntry,
  importGoodsDataByUploadFile,
  configList,
  getEntryInfo,
  getEntryStatus,
} from "@/api/goods/goodsWarehousing/warehousing";
export default {
  components:{editTable,basisInfo},
  props:["propsid"],
  data() {
    return {
      clientHeight: document.documentElement.clientHeight,   //实时屏幕高度
      formQuery:{
        input:"",
        state:""
      },
      tableData:{
        page:1,//当前页数
        total:200,//列表总数
      },
      topHeight:0,//基础信息动态变化的高度
      mian_tabStyle:"",
      editTable:"",
      editTableHeight:240,
      detailQuery:{},
      templateList: [],
      templateTotal: 0,
      token:'',
      baseUrl: "",
      templateVisiable:false,
      tableVisiable:false,
      loading:false,
      templateName: "新增表头模板",
      template_name: "",
      fieldList: [],
      goodsinfo_map: {},
      orderDetail:{},
      setData:[],//表单默认以及编辑回填数据
      setPermissionsData:{},//权限配置对照数据
      AuthpermissionsData:{},//系统权限数据
      h:'',
      customerTitle: [],
      filed: {},
      text:222
    };
  },

  created() {
    this.detailQuery=this.$route.query;
    this.setPermissionsData=permissionsData.godownEntry;//获取入库列表列表权限对照表
    this.getMyAuth();
    this.token = storage.session.get("token") || Cookies.get("token");
    const env = process.env.NODE_ENV;
    const host = window.location.host;
    //表单新增默认数据
    let list=[
          {
            field:"goods_number_type",
            key:parseInt(this.detailQuery.goods_number_type),
            box_type: "radio",
            value:""
          },
          {
            field:"goods_type_id",
            key:parseInt(this.detailQuery.goods_type_id),
            box_type: "radio",
            value:""
          }
        ];
    this.setData=list;
    //下面文本类型，输入框类型不用管，只用于展示

    if (env === "development") {
      this.baseUrl = "http://api.dev.etouch.vip";
    } else {
       if (host === "edianbao.dev.etouch.vip") {
        this.baseUrl = "http://api.dev.etouch.vip";
      }
      if (host === "edianbao.test.etouch.vip") {
        this.baseUrl = "https://api.test.etouch.vip";
      }
      if (host === "www.etouch.top") {
        this.baseUrl = "https://api.etouch.top";
      }
      if (host === "edianbao.pretest.etouch.vip") {
        this.baseUrl = "https://api.pretest.etouch.vip";
      }
      if (host === "edianbao.test2.etouch.vip") {
        this.baseUrl = "http://api.test2.etouch.vip";
      }
    }
  },
  mounted () {
    const _that=this;
    this.h=document.documentElement.clientHeight;
    window.addEventListener('resize', this.resizeFun);

    // this.$route.query.istype
    //页面加载完后执行
    this.$nextTick(() => {
      setTimeout(()=>{
        if(this.$route.query.istype=='detail'){
          this.getEntryInfo(this.$route.query.entry_id)
        }else{
          this.getDefaultFormValue();

        }
        this.clientHeight=document.documentElement.clientHeight;
      },300)
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {

        vm.tabstyle()
    })
  },
  methods: {

    getSelect(obj){
      //字段,角标,id
      this.$refs.child.changeNoteList(obj[0].obj,obj[0].type)
    },

    resizeFun(){
      this.h=document.documentElement.clientHeight;
      this.tabstyle();
    },
    //设置表单默认值
    getDefaultFormValue(){
      getDefaultFormValue({type:'entry'}).then((res) => {
        let list=[{field:"entry_number",value:"-"},{field:"entry_status",value:"未审核"}];
        const mergeList = [...res.data.listMap, ...list];
        this.$refs.basisInfoChild.setDate(1,mergeList)
      })
      //
    },
    //设置表单的状态
    getformSatus(status){
      this.$refs.basisInfoChild.formDisabledfun(status)
    },
     //获取系统权限
    getMyAuth(){
      getMyAuth({}).then((res) => {
        this.AuthpermissionsData=res.data;
        this.getEntryStatus();
      })
    },

    getEntryStatus(){
      getEntryStatus({}).then((res) => {
        if(res.code==1){
          this.options=res.data;
        }
      })
    },

     /*
      *  获取入库单详情
      */
      getEntryInfo(entry_id,type) {
        getEntryInfo({ entry_id: entry_id }).then((res) => {
          if (res.code === 1) {
            this.orderDetail=res.data
            if(type==2){
              this.$refs.basisInfoChild.setDate(2,res.data.listMap)
            }
          }
        });
      },

      flushListMap(type,data,falg){
        this.getformSatus(falg)
        this.$refs.basisInfoChild.setDate(type,data)
      },

      //获取子组件传参
      getChildParams(obj){
        this.orderDetail=obj
      },

    //添加数据
    addData(){
      this.$refs.child.addData()
    },//打开设置弹窗
    setInfo(){
      this.$refs.basisInfoChild.setStatusFun(true)
    },

    createWareHouse(){
      this.$refs.child.createWareHouse()
    },

     //入库单详情操作按钮父组件调用子组件方法
    parentMethod(type){
      if(type==1||type==2||type==13||type==3){
          this.getfromInfo(type);
      }else if(type==11||type==14){
          this.getfromInfo(type,true);
      }
      else{
        this.$refs.child.childMethod(type);
      }
    },

    //获取表单数据
    getfromInfo(type,status){
      this.$refs.basisInfoChild.setInfoFun(type,status);
    },//接受表单数据

    receiveFromInfo(data){
      this.$refs.child.childMethod(data.type,data.InfoData);
    },

    //计算表格高度属性
    tabstyle(){

      this.$nextTick(() => {
        let h =this.h;
        let topHeight = this.$refs.top.offsetHeight;
        // this.topHeight=topHeight;

        // 有分页用注释的部分
        // this.mian_tabStyle={height: `calc(${h} - ${topHeight} - 240px)`};
        // this.editTable={height: `calc(${h} - ${topHeight} - 240px)`};
        // this.editTableHeight=this.clientHeight - this.topHeight - 300;
        let shight=h-topHeight - 160+"px"
        this.mian_tabStyle={height:shight};
        this.editTable={height: shight};
        this.editTableHeight=this.h - topHeight - 240;
        if(this.editTableHeight<240){
          this.editTableHeight=240;
        }
      });

    },


     /*
     *  选择对应的表头模板
     */
    handleChooseTemplate(data) {
      this.goods_type_import_id = data.goods_type_import_id;
      this.templateName = data.template_name;
      getTemplateInfo({ goods_type_import_id: data.goods_type_import_id }).then(
        (res) => {
          if (res.code === 1) {
            this.tableVisiable = true;
            this.templateVisiable = false;
            this.goodsinfo_map = res.data.fields_map[0];
            Object.keys(res.data.fields_map[0]).forEach((item) => {
              let isExit = false;
              this.downFiledList.forEach((items) => {
                if (
                  res.data.fields_map[0][item] === items.name ||
                  "*" + res.data.fields_map[0][item] === items.name
                ) {
                  isExit = true;
                }
              });
              if (isExit === false) {
                res.data.fields_map[0][item] = "";
              }
            });
          }
        }
      );
    },


    /*
     *  表头设置
     */
    handleSureTable(type) {
      this.loading = true;
      delete this.goodsinfo_map.index;
      const data = {
        goods_type_import_id: this.goods_type_import_id,
        reload: 1,
        goods_type_id: this.detailQuery.goods_type_id,
        goods_number_type:this.detailQuery.goods_number_type,
        goodsinfo_map: [this.goodsinfo_map],
        source: "list",
      };
      if (type === 10) {
        data.isSave = 0;
      }
      importGoodsDataByUploadFile(data)
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.tableVisiable = false;
            this.templateName = "";
            this.goods_type_import_id = 0;
            this.goodsinfo_map = {};
            this.$refs.child.uploadData(res.data.entryList)
            this.noWatch = false;
          } else {
            this.loading = false;
          }
        })
        .catch((res) => {
          this.loading = false;
        });
    },


     /*
     *  获取表头模板列表
     */
    getTemplateList() {
      templateList({ page: 1 }).then((res) => {
        if (res.code === 1) {
          this.templateList = res.data.list;
          this.templateTotal = res.data.total;
        }
      });
    },


     /*
     *  导入货品数据
     */
    handleGoodsSuccess(res, file) {
       this.getAttrList2()
      if (res.code === 1) {
        this.templateVisiable = true;
        this.getTemplateList();
        this.customerTitle = res.data.customerTitle;
        this.downFiledList = res.data.customerTitle.map((item, index) => {
          var info = {};
          info.name = String(item);
          info.disabled = false;
          return info;
        });
      } else {
        this.$message({
          type: "error",
          message: res.msg,
        });
      }
    },

      /*
     *  新增配置表头模板
     */
    handleConfigTemplate(type) {
      if (this.templateName === "新增表头模板") {
        if (this.template_name === "") {
          this.$message({
            type: "error",
            message: "请填写模板名称",
          });
        } else {
          const data = {
            goods_type_id:this.detailQuery.goods_type_id,
            template_name: this.template_name,
            goodsinfo_map: [this.goodsinfo_map],
          };

          this.loading = true;
          addTemplateInfo(data)
            .then((res) => {
              if (res.code === 1) {
                if (type === 10) {
                  this.tableVisiable = false;
                  this.templateVisiable = true;
                  this.goodsinfo_map = {};
                  this.loading = false;
                  this.getTemplateList();
                } else if (type === 20) {
                  this.goods_type_import_id = res.data;
                  this.handleSureTable(20);
                }
              } else {
                this.loading = false;
              }
            })
            .catch((res) => {
              this.loading = false;
            });
        }
      }
    },

     /*
     *  获取品类字段
     */
    getAttrList2(id) {
      const data = {
        goods_type_id:this.detailQuery.goods_type_id,//obj.goods_type_id,
        fun:'EntryDetails',
        is_all:0,
        mode:'column'
      };
      configList(data).then((res) => {
        let newFiled = {};
        let len = res.data.length;
        this.fieldList = res.data;
        let newArr = res.data.map((item, index) => {
          item.prop = String(item.field);
          item.label = String(item.field_text);
          newFiled["index"] = 0;
          newFiled[item.field] = "";
          this.filed[item.field] = "";
          return item;
        });
        const newList = [];
        newList.push(newFiled);
        this.goodsinfo_map = { ...newFiled };

        this.tableList = [...newList];
        this.rowList = [...newArr];
        this.batchEditForm = { ...this.filed };
      });
    },


     /*
     *  删除表头模板
     */
    handleDelTemplate(data) {
      const info = {
        goods_type_import_id: data.goods_type_import_id,
        type: "delete",
      };
      this.$confirm("确定要删除该表头模板吗？删除后将无法恢复。", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        addTemplateInfo(info).then((res) => {
          if (res.code === 1) {
            this.$message({
              type: "success",
              message: "删除成功！",
            });
            this.getTemplateList();
          }
        });
      });
    },

    //增加模板
    handelAddTemplate() {
      this.fieldList.forEach((item) => {
        this.downFiledList.forEach((items) => {
          const name =
            items.name.indexOf("*") != -1
              ? items.name.split("*")[1]
              : items.name;
          if (item.field_text === name) {
            this.goodsinfo_map[item.field] = item.field_text;
          }
        });
      });
      this.tableVisiable = true;
      this.templateVisiable = false;
      this.loading = false;
      this.template_name = "";
      this.templateName = "新增表头模板";
    },

     cancelTemplateTable() {
      this.templateVisiable = false;
    },

     handleCurrentChange(val) {
      templateList({ page: val }).then((res) => {
        if (res.code === 1) {
          this.templateList = res.data.list;
          this.templateTotal = res.data.total;
        }
      });
    },

     handleDisableFileList() {
      for (let item in this.goodsinfo_map) {
        this.downFiledList = this.downFiledList.map((item, index) => {
          if (index === Number(this.goodsinfo_map[item])) {
            item.disabled = true;
          }
          return item;
        });
      }
    },

    /*
     *  将品类多维数组转为对象
     */
    cancelTable() {
      this.tableVisiable = false;
      this.templateVisiable = true;
      this.loading = false;
      this.goodsinfo_map = {};
      this.getTemplateList();
    },

  },
};
</script>

<style lang="less" >
  .godownEntryListDetails{
    .el-input .el-input__inner {
      font-size: 14px;
      height: 32px;
      line-height: 32px;
    }
    .mian_footer{
        position: sticky;
        left: 0px;
        bottom: 0px;
        background: #fff;
        width: calc(100% );
        padding-bottom: 10px;
       .mian_page{
        padding: 15px 0;
        background: #fff;
        margin-right: 20px;
        display: flex;
        justify-content: flex-end;

        .el-pagination.is-background .el-pager li {
            border-radius: 2px;
            font-weight: normal;
            background-color:#FFFFFF;
            border: 1px solid #D9D9D9;
            width: 32px;
            height: 32px;
            line-height: 32px;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active {
            background-color: #409EFF;
            color: #FFF;
        }
        .el-pagination.is-background .btn-prev {
            border-radius: 2px;
            border: 1px solid #ddd;
            background: white;
            height: 32px;
            line-height: 32px;
        }
        .el-pagination.is-background .btn-next {
            border-radius: 2px;
            border: 1px solid #ddd;
            background: white;
            height: 32px;
            line-height: 32px;
        }

      }
    }


  }

</style>
<style lang="less" scoped>
.godownEntryListDetails{
  margin: 0 10px;
  /deep/ .el-form-item__label:before {
      content: '*';
      color: #fff;
      margin-right: 4px;
  }
}
.el-icon-setting{
  font-size: 18px;
  cursor: pointer;
}

.el-form--inline .el-form-item__label {
    float: none;
    display: inline-block;
}
.godownEntryListDetails{
  margin: 5px 10px;
  // height: calc(100vh - 230px);
  height: calc(100vh - 160px);
  padding-bottom: 60px;
  position: relative;
}
  .mian_top{
    background: #fff;
    padding: 12px 20px;
    margin-bottom: 12px;
    .mian_top_right{
      float: right;
      display: flex;
      padding-top:3px;
      .senior_screening_btn{
        width: 80px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #0075FF;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #0075FF;
      }
      .senior_screening_query{
        width: 68px;
        height: 32px;
        line-height: 32px;
        background: #0075FF;
        text-align: center;
        color: #fff;
        border-radius: 4px 4px 4px 4px;
        margin-left: 20px;
        margin-right: 8px;
      }
      .senior_screening_reset{
        width: 68px;
        height: 32px;
        line-height: 32px;
        background: rgba(255,255,255,0.1);
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #D8D8D8;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #595959;
        text-align: center;
      }
    }
  }
  .queryCondition{
    display: inline-block;
    margin: 0;
    margin-right: 20px;
    font-size: 12px;
    margin-bottom:8px;
  }
  .mian_tab{
    height:calc(100% - 154px);
    background: #fff;
    padding: 12px 20px;
    min-height:300px;
    .editTable{
      height:calc(100% - 1540px);
      min-height:300px;
    }
    .headerBox_title{
      font-size: 16px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #595959;

    }

  }
  .headerBox{
    overflow: hidden;
    .headerBox_operate{
      float: right;
      margin-bottom: 12px;
      margin-top: 2px;
    }

  }
  .mian_top{
      .headerBox{
        .headerBox_title{
          font-size: 16px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #595959;
          .headerBox_right{
            float: right;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #595959;
            display: flex;
            align-items: center;
            cursor:pointer;
            span{
              margin-left:8px;
            }
          }
        }
      }

    }
</style>
