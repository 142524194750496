<template>
  <div class="basisInfoTab">
    <el-form :model="formQuery" :inline="true" label-position="left" style="padding-top:10px" ref="formQuery" :disabled="formDisabled">

      <el-form-item  v-for="(item, index) in list" :key="item.field" :label="item.field_text" label-width="100px" class="queryCondition"
        :prop="item.field"
        :rules="setRules(item)"
        v-if="item.is_auth!='0'" >

        <!-- 文本类型展示:text -->
        <span :style="'width:'+item.width+'px'" v-if="item.box_type=='text'"  class="infoLi">
          <el-input class="queryCondition_input"  :style="'width:'+item.width+'px'"  v-model="formtext[item.field]" disabled></el-input>
        </span>
        <!-- 时间段选择:date -->
        <span v-if="item.box_type=='date'" class="infoLi" :style="'width:'+item.width+'px'" >
            <el-date-picker
            @change="getSelect(item)"
              value-format="yyyy-MM-dd"
              :style="'width:'+item.width+'px'"
              :placeholder="item.is_edit==1?item.field_text:''"
              v-model="formQuery[item.field]"
              type="date"
              :disabled='item.is_edit==0'
               :picker-options="pickerOptions"
            >
            </el-date-picker>
        </span>
        <!-- 文本输入框:string -->
        <span v-if="item.box_type=='string'" class="infoLi string" :style="'width:'+item.width+'px'">
          <el-input class="queryCondition_input"  :style="'width:'+item.width+'px'" :placeholder="item.is_edit==1?item.field_text:''" v-model="formQuery[item.field]"  @keydown.enter.native="onSubmit"   :disabled='item.is_edit==0'></el-input>
        </span>
        <!-- 下拉框单选：radio  -->
        <span v-if="item.box_type=='radio'||item.box_type=='radio_no_option'" class="infoLi" :style="'width:'+item.width+'px'">
          <el-select v-if="!setList[item.field]" @change="getSelect(item)" v-model="formQuery[item.field]"  :placeholder="item.is_edit==1?item.field_text:''" filterable clearable :style="'width:'+item.width+'px'" :disabled='item.is_edit==0'>
            <el-option
              v-for="childItem in item.option"
              :key="childItem.key"
              :label="childItem.value"
              :value="childItem.key">
            </el-option>
          </el-select>
          <el-select v-else  @change="(val)=>{ associatedFun(val,item)}" v-model="formQuery[item.field]"  :placeholder="item.is_edit==1?item.field_text:''" filterable clearable :style="'width:'+item.width+'px'" :disabled='item.is_edit==0'>
            <el-option
              v-for="childItem in item.option"
              :key="childItem.key"
              :label="childItem.value"
              :value="childItem.key">
            </el-option>
          </el-select>
        </span>
        <!-- 下拉框远程搜索:search -->
        <span v-if="item.box_type=='search'"  class="infoLi" :style="'width:'+item.width+'px'">
          <el-select @change="getSelect(item)" v-model="formQuery[item.field]" filterable :style="'width:'+item.width+'px'"   remote reserve-keyword :loading="loading" :remote-method="(query) => getProductList(query, item,index)" :disabled='item.is_edit==0' :placeholder="item.is_edit==1?item.field_text:''">
            <template>
              <el-option
                v-for="childItem in item.option"
                :key="childItem.key"
                :label="childItem.value"
                :value="childItem.key">
              </el-option>
            </template>
          </el-select>
        </span>
      </el-form-item>
    </el-form>
    <!-- 弹窗设置 -->
    <el-dialog title="设置基础信息" :visible.sync="dialogTableVisible" @close="closeDialogTableVisible" :closeOnClickModal='false'>
      <div class="spanBtn">
        <span @click="restore">
          恢复默认
        </span>
        <span @click="moveUp">
          上移
        </span>
        <span @click="moveDown">
          下移
        </span>
      </div>
      <vxe-table height="400" ref="xTable1" border resizable show-overflow :data="allList"
      v-if="reserveStatus"
      align="center"
      id="xTable1s"
      row-class-name="v-align-t"
      @checkbox-all="selectAllEvent" @checkbox-change="selectChangeEvent"
      :row-config="{height: 39, isCurrent: true, isHover: true}"
      :checkbox-config='{reserve: true }'
      :edit-config="{trigger: 'click', mode: 'cell',showIcon:false}">
        <vxe-column  type="checkbox" width="60" style="text-align:center"  align="center"></vxe-column>
        <vxe-column type="seq" width="60" title="序号" align="center"></vxe-column>
        <vxe-column v-for="(item,index) in tableColumn" :key="index" :title="item.title" :field="item.field" :type="item.type" :width="item.width"  align="center">

            <template #default="{ row }" >
              <span v-if="item.type=='text'">{{row.field_text}}</span>
              <vxe-select v-model="row.alignment"  v-if="item.type=='select'">
                <vxe-option style="position:relative;" v-for="item in options" :key="item.value" :value="item.value" :label="item.label">
              </vxe-option>
            </vxe-select>
            <!-- is_edit是否可以编辑，当is_compulsory为必填的时候，默认是可以显示的-->
            <vxe-switch v-if="item.type=='radio1'" v-model="row.is_use" size="medium" open-value="1" close-value="0" :disabled='row.is_handle_compulsory ==0&&row.is_compulsory==1'  @change="turnOnOff(row,1)"></vxe-switch>
            <vxe-switch v-if="item.type=='radio2'" v-model="row.is_compulsory" size="medium" open-value="1" close-value="0" :disabled='row.is_handle_compulsory ==0' @change="turnOnOff(row,2)"></vxe-switch>
          </template>
        </vxe-column>

      </vxe-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="setDispose(false)">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import DatePicker from "./DatePicker.vue";
import {configuration,setConfig,remoteSet,configRestore,associatedSet} from "@/api/components/index.js";

import moment from 'moment';
import { Alert } from 'element-ui';
import { json } from "body-parser";
export default {
  components: {
    DatePicker
  },
  name: "Draggable",
  props:['setData'],//接收父组件参数
  data() {
    return {
      copyList:[],
      watchIndex:0,
      hideArr:[],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      dialogTableVisible:false,
      formQuery:{},//选项绑定提交值集合,需要表单校验的集合
      formQuery2:{},
      formtext:{},
      loading:false,
      list:[],//基础信息列表数据
      allList:[],//获取所有基础信息列表数据
      tableColumn: [
        {title:'列名', type:'text',field: 'field_text',},
        {title:'是否显示',type:'radio1',field:'is_use',},
        {title:'是否必填',type:'radio2',field:'is_compulsory',},
      ],
      reserveStatus:true,
      listParams:{}, //传递的参数
      spliceIndex:[], //角标数组
      spliceArr:[],   //上下移动的数组
      columnObj:{},    //记录列的数据
      isAdaption:false,
      formDisabled:false,//表单的禁用
      setList:{},//下拉关联搜索对象
      nameSetList:[],//下拉关联名称机会，去重集合
      basisInfoData:{
        fun:"EntryDetails",
        mode:'form'
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: '今日',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          },
          {
            text: '昨日',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          },
          {
            text: '本周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getDay(), start.getDate(), 'start')
              var thisDay = start.getDay() - 1;
              var thisDate = start.getDate();
              if (thisDay != 0) {
                start.setDate(thisDate - thisDay);
              }
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '上周',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          },
             {
            text: '本月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setDate(1);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '上个月',
            onClick(picker) {
              const oDate = new Date();
              var year = oDate.getFullYear();
              var month = oDate.getMonth();
              var start, end;
              if (month == 0) {
                year--
                start = new Date(year, 11, 1)
                end = new Date(year, 11, 31)
              } else {
                start = new Date(year, month - 1, 1)
                end = new Date(year, month, 0);
              }

              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '今年',
            onClick(picker) {
              let end = new Date();
              let start = moment().startOf('year').format('YYYY-MM-DD');
              start=new Date(start);

              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '本季度',
            onClick(picker) {
              let start = moment().startOf('quarter').format('YYYY-MM-DD');
              let end=moment().endOf('quarter').format('YYYY-MM-DD');
              start=new Date(start);
              end=new Date(end);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '上季度',
            onClick(picker) {
              let start = moment().quarter(moment().quarter() - 1).startOf('quarter').format('YYYY-MM-DD')
              let end=moment().quarter(moment().quarter() - 1).endOf('quarter').format('YYYY-MM-DD')
              start=new Date(start);
              end=new Date(end);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      isClose:false,
    };
  },

  created() {
    this.getBasisInfo();
  },
  methods: {

    closeDialogTableVisible(){
      if(this.isClose){
        this.setDispose(true)
      }
    },
    
    getSelect(item){
      let key=item.field+item.sindex
      let obj={[key]:true}
      if(this.formQuery[item.field]!=this.formQuery2[item.field]){
        //不匹配
        this.$emit('getSelect',{obj,type:1})
      }else{
        this.$emit('getSelect',{obj,type:2})
      }
    },  

    turnOnOff(row,type){
      let index=this.allList.findIndex(item => item.field == row.field);
      if(type==1){
        if(this.allList[index].is_use==0){
          row.is_compulsory='0';
          this.$set(this.allList,index,row);
        }
      }else{
        if(this.allList[index].is_compulsory==1){
          row.is_use='1';
          this.$set(this.allList,index,row);
        }
      }
    },
    setRules(item){
      if(item.is_compulsory==1){
        return  {required:true, message:item.field_text+'不能为空', trigger: blur}
      }

    },//设置表单的禁用
    formDisabledfun(status){
      this.formDisabled=status;
    },
    //设置新增以及编辑的默认值
    setDate(type,data){//type1,新增，2编辑
      if(type==1){
        //设置文本不需要回填
        for(let i=0;i<data.length;i++){
           this.$set(this.formtext,data[i].field,data[i].value);
        }
        this.backfillData(data)
      }else if(type==2){
        console.log(data)
        this.backfillData(data)
        for(let a=0;a<this.nameSetList.length;a++){
          let index=this.list.findIndex(item => item.field == this.nameSetList[a]);
          console.log(this.formQuery[this.nameSetList[a]])
          this.associatedFun(this.formQuery[this.nameSetList[a]],this.list[index],1)
        }


      }
    },
    backfillData(data){
      for(let i=0;i<data.length;i++){
          if(data[i].box_type=="text"){

            this.$set(this.formtext,data[i].field,data[i].value);
          }else{
            if(data[i].box_type=="date"||data[i].box_type=="string"){
              this.$set(this.formQuery,data[i].field,data[i].value);
            }else if(data[i].box_type=="search"){
              let index=this.list.findIndex(item => item.field == data[i].field);
              if(data[i].key!=""){
                this.list[index].option=[];
                this.list[index].option.push(data[i])
                this.$set(this.formQuery,data[i].field,data[i].key);
              }
            }else{
              if(data[i].key!=""){
                this.$set(this.formQuery,data[i].field,data[i].key);
              }

            }

          }
        }
        
        this.formQuery2=JSON.parse(JSON.stringify(this.formQuery))
    },
    //设置新增需要回填的数据，新增和编辑分开写，会异步
    setDatafun(){
      for(let i=0;i<this.setData.length;i++){
          if(this.setData[i].box_type=="radio"){
            this.$set(this.formQuery,this.setData[i].field,this.setData[i].key);
          }
          if(this.setData[i].box_type=="date"||this.setData[i].box_type=="string"){
            this.$set(this.formQuery,this.setData[i].field,this.setData[i].value);
          }

      }
    },
    //恢复默认
    restore(){
      this.$confirm('确定恢复默认?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
      }).then(() => {
        configRestore({
          fun:'EntryDetails',
          mode:'form',
        }).then(res=>{
          if(res.code==1){
            this.isClose=true
            this.hideArr=[]
            this.allList=[]
            this.spliceIndex=[];
             res.data.forEach((item,index)=>{
              if(item.is_auth=='1'){
                this.allList.push(item)
              }else{
                this.hideArr.push(item)
                console.log(this.hideArr)
              }
            })
            this.$message({
              message: '恢复默认成功',
              type: 'success'
            });
              }
            })
        }).catch({});
    },
      //全部显示/隐藏
    allShow(type,from){
      if(from=='show'){
        this.allList.forEach((item,index)=>{
          item.is_use=type
        })
      }else{
        this.allList.forEach((item,index)=>{
          item.is_freeze=type
        })
      }
    },
    //向上移动
    moveUp(){
      let min=Math.min(...this.spliceIndex);
      if(min===0){
        return false;
      }
      this.spliceIndex=this.spliceIndex.sort((a, b) => a - b);
      let list=JSON.parse(JSON.stringify(this.allList));
      for(let i=0;i<this.spliceIndex.length;i++){
        [list[this.spliceIndex[i]],list[this.spliceIndex[i]-1]]=[list[this.spliceIndex[i]-1],list[this.spliceIndex[i]]]
        this.spliceIndex[i]-=1
      }
      this.allList=[...list];
      let that=this;
      setTimeout(function(){//延时调用组件传递方法
          that.$refs.xTable1.scrollToRow(list[that.spliceIndex[0]]);
      },200);
    },

    //向下移动
    moveDown(){
      console.log(this.spliceIndex)
      let max=Math.max(...this.spliceIndex);
      let scrollHeight = this.$refs.xTable1
      if(max===this.allList.length-1){
        return false
       }
       this.spliceIndex=this.spliceIndex.sort((a, b) => a - b)
       this.spliceIndex=this.spliceIndex.reverse()
       let list=JSON.parse(JSON.stringify(this.allList));
      for(let i=0;i<this.spliceIndex.length;i++){
        [list[this.spliceIndex[i]],list[this.spliceIndex[i]+1]]=[list[this.spliceIndex[i]+1],list[this.spliceIndex[i]]]
        this.spliceIndex[i]+=1
      }
      this.allList=[...list];
      let that=this;
      setTimeout(function(){//延时调用组件传递方法
          that.$refs.xTable1.scrollToRow(list[that.spliceIndex[0]]);
      },200);
    },
    //设置列表 全选
    selectAllEvent({ checked }){
          const records = this.$refs.xTable1.getCheckboxRecords()
          if(checked){
              records.forEach((item,index)=>{
                this.spliceIndex.push(index)
              })
              this.spliceArr=records
          }else{
            this.spliceIndex=[]
            this.spliceArr=[]
          }
      },

    selectChangeEvent({ row,rowIndex,checked }){

      console.log(row,checked )
      if(checked){
        this.spliceIndex.push(rowIndex)
        this.spliceArr.push(row)
      }else{
        let index= this.spliceIndex.indexOf(rowIndex)
        this.spliceIndex.splice(index,1)
        this.spliceArr.splice(index,1)
      }
    },
   //保存表格配置
    setDispose(falg){
      let list;
      if(falg){
        list=JSON.parse(JSON.stringify(this.copyList));
      }else{
         this.isClose=false
         list=JSON.parse(JSON.stringify(this.allList));
      }
      for(let i=0;i<list.length;i++){
        list[i].order=(1000-i).toString();
        list[i].option=[];
      }
      console.log(this.allList)
      //保存表格原始数据
      let newformQuery=JSON.parse(JSON.stringify(this.formQuery))
      setConfig({
        list:[...list,...this.hideArr],
        fun:'EntryDetails',
        mode:'form',
      }).then(res=>{
        if(res.code==1){

          this.dialogTableVisible=false;
          if(!falg){
             this.$message({
                message: '保存成功',
                type: 'success'
              });
            }
          }
          this.getBasisInfo(3,newformQuery);
      })
    },
    //获取基础信息数据
    getBasisInfo(type,newformQuery){
      let oData=JSON.parse(JSON.stringify(this.basisInfoData))
      if(type==1){//获取所有基础信息数据，用于设置表格
        oData.is_all=1;
      }
      configuration(oData).then((res) => {
        console.log(res);
        if(res.code==1){
          if(type==1){
            this.allList=[]
            this.hideArr=[]
            this.copyList=[]
            this.reserveStatus=true;
            res.data.forEach((item,index)=>{
              if(item.is_auth=='1'){
                this.allList.push(item)
                this.copyList.push(item)
              }else{
                this.hideArr.push(item)
                console.log(this.hideArr)
              }
            })
            // this.allList=[...res.data];
            this.spliceIndex=[];
          }else{
            let setList={};
            let nameSetList=[];
            this.setFormQuery(res.data);
            for(let i=0;i<res.data.length;i++){
              if(res.data[i].as_field){
                if(!setList[res.data[i].as_field]){
                  setList[res.data[i].as_field]=[]
                }
                nameSetList.push(res.data[i].as_field)
                res.data[i].sindex=i;
                setList[res.data[i].as_field].push(res.data[i])
              }

            }

            this.nameSetList= [...new Set(nameSetList)];
            this.setList=setList;
            if(type==3){//保存表单数据后数据回填
              this.formQuery=newformQuery;
              for(let a=0;a<this.nameSetList.length;a++){
                let index=this.list.findIndex(item => item.field == this.nameSetList[a]);
                this.associatedFun(this.formQuery[this.nameSetList[a]],this.list[index],1)
              }
            }else{
              this.setDatafun();
            }


          }
        }
     })
    },//下拉关联数据查询
    associatedFun(val,item,type){
      console.log(val)
      let list=this.setList[item.field];
      if(list){
        for(let i=0;i<list.length;i++){
          let data={};
          data[list[i].as_field]=val;

          this.associatedSet(data,list[i].select_code,list[i].sindex,type)
        }
      }
      if(this.watchIndex>0){
        this.getSelect(item)
      }
      this.watchIndex++
    },//下拉清空值
    associatedSet(data,select_code,sindex,type){
      associatedSet(data,select_code).then((res) => {
        if(res.code==1){
          this.list[sindex].option=res.data;
          if(type!=1){//等于1清空下拉值
            this.$set(this.formQuery,this.list[sindex].field,'');
          }else{
            this.$set(this.formQuery,this.list[sindex].field,this.formQuery[this.list[sindex].field]);
          }

        }
      })
    },
    setStatusFun(status){
      this.dialogTableVisible=status;
      this.reserveStatus=false;
      this.getBasisInfo(1)
    },
    //接收父组件传递的参数,表单校验返回表单的值
    setInfoFun(type,status){
      if(!status){
        this.$refs.formQuery.validate((valid) => {
          if (valid) {
            this.$emit("receiveFromInfo",{type:type,InfoData:this.formQuery});
          }
        })
      }else{
        this.$emit("receiveFromInfo",{type:type,InfoData:this.formQuery});
      }
    },

    //远程搜素
    getProductList(query,res,index){
      if (query !== '') {
        this.loading = true;
        console.log(res);
        remoteSet({select_code:res.select_code,search:query}).then((res) => {
          console.log(res)
          if (res.code === 1) {
             this.loading=false;
           this.list[index].option=res.data
          }
        })
      } else {
          this.loading=false;
          this.list[index].option = [];
      }
      console.log(this.list)
    },
    setFormQuery(list){
      //键值为字符串时才能绑定
      for(let i=0;i<list.length;i++){
        //文本不处理
        if(list[i].box_type!='text'){
          this.$set(this.formQuery,list[i].field,'');
        }
        if(list[i].box_type=='date'){
          let date= new Date()
          this.$set(this.formQuery,list[i].field,date);
        }
      }
      this.formQuery=this.formQuery;
    
      this.list=list;
      let that=this;
      setTimeout(()=>{
        that.$emit('tabstyle');
      },300)

    },
  },
};
</script>
<style lang="less" >
  .basisInfoTab{
    .el-form-item__error{
      padding: 0;
    }
     .el-form-item__content{
        line-height: 32px;
      }
      .el-form-item__label{
        line-height: 32px;
      }
      .el-input__icon{
        line-height: 32px;
      }
    .datePicker{
      height: 40px;
      .el-input__inner {
        font-size: 14px;
        height: 32px;
        line-height: 32px;
        padding: 0;
        width:220px;
      }
      .el-icon-date{
        margin-left: 6px;
      }

      .el-range-input{

      }


    }
    .v-align-t{
      .vxe-cell{
        padding-top:6px;
      }
    }
    .el-dialog__body{
      padding-top:8px;
    }
  }

</style>
<style  scoped lang="less" >
  .queryCondition{
    display: inline-block;
    margin: 0;
    margin-right: 20px;
    font-size: 12px;
    padding-bottom:16px;
    .infoLi{
      display: inline-block;
      text-align: left;
    }


  }
  .spanBtn{
    display: flex;
    justify-content:flex-end;
    span{
        cursor: pointer;
        display: inline-block;
        padding:0 10px;
        margin:10px 0;
        color: #007BFF;
        border-right: 1px solid #E6E6E6;
    }
  }

</style>
