<template>
  <div >
      <div class="headerBox">
          <div class="opeator">
              <span class="opeaTitle">货品信息</span>

              <template v-if="orderDetail.entry_status_id!=20">
                <el-button  icon="el-icon-document-copy"
                  size="mini"
                  @click.native="handleBatchCopy"
                  >批量复制行</el-button>
                <el-button  icon="el-icon-edit-outline" size="mini" @click="setBatch">批量修改</el-button>
                <el-button size="mini" icon='el-icon-delete-solid' @click="clearStore">清空商品</el-button>
              </template>

          </div>

          <div>
            <div style="display:inline-block;">
              <!-- <el-checkbox v-model="checked">图片管理</el-checkbox>
              <el-checkbox v-model="checked">称重打签</el-checkbox> -->
            </div>

            <i class='el-icon-setting' v-if='AuthpermissionsData[setPermissionsData.setConfig.toLowerCase()]' @click="showdialogTableVisible"></i>
            <i class='el-icon-full-screen' @click="fullScreen"></i>

          </div>
      </div>

      <!-- 表格 -->
      <div>
          <!-- use-virtual -->
          <u-table
              class="listMyTable"
              border
              ref="myTable"
              :data="tableData"
              style="'width: 100%;"
              row-key="index"
              :height="editTableHeight"
              :loading="loading"
              @cell-click="cellClick"
              @cell-dblclick="cellClick"
              @row-click="clickRow"
               @sort-change="sortChange"
              :row-class-name="tableRowClassName"
              :cell-class-name="tableCellClassName"
              @row-contextmenu="rightClick"
              :data-changes-scroll-top="false"
              @select="getSelect"
              @select-all="tableGetAll"
              @header-dragend="changeColumnWidth"
              highlight-current-row
               resizable="false"
              :header-cell-style="{textAlign:'center'}"
              show-summary
              :summary-method="getSummaries"

                showHeaderOverflow="title"
                :row-height="45">

                <u-table-column type="selection" :reserve-selection="true"  fixed="left" width="65" align="center"></u-table-column>

                <u-table-column
                      label="序号"
                      fixed="left" width="80" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.$index==currentIndex&&orderDetail.entry_status_id!=20" class="iconDiv">
                              <i @click="addData" class="el-icon-circle-plus"></i>
                              <i @click="cutData" class="el-icon-remove"></i>
                            </div>
                            <span v-else>{{parseInt(scope.$index)+(1*1)}}</span>

                        </template>
                  </u-table-column>

                <!-- :width="flexColumnWidth(item.field_text,item.field)" -->
              <u-table-column v-for="(item,index) in rowList" :fixed="item.is_freeze=='1'" :key="index+''+item.field"
                   :prop="item.field" :align="getAlign(item.alignment,item.field_type)"
                  :label="item.field_text" show-overflow-tooltip
                  :width="item.width">
                  <!-- 输入框 -->
                  <template slot="header">
                    <div style="display:inline-block;position:relative;left:-5px;">
                      <span style="color: #f56c6c" v-if="item.is_compulsory =='1'"
                        >*</span
                      >{{ item.field_text }}
                    </div>
                  </template>
                  <template slot-scope="scope">

                      <!-- @input="allNumber(formQuery[item.field][0],item.field,0)" -->
                      <el-input v-if="(item.type==10||item.type==30||item.type==40)&&scope.row.isEdit" v-model="tableData[scope.$index][item.field]" :disabled="item.is_edit=='0'||(item.field=='pcs'&&listParams.goods_number_type!='20')||item.field=='cost_price_new'"
                       @keyup.enter.native="keyUp($event,item.field,scope.row.index,scope.row,index,keyUpList[0])"
                       :id="'row' + scope.row.index+'line'+index"
                       @blur="getBlur($event,scope.row.index,item.field,item.decimal)"
                       @input="
                        handleAttrInput(
                          $event,
                          item.field,
                          scope.row.index,
                          scope.row,
                          1,
                          item.decimal,
                          '',
                          scope.row.entry_goods_id,
                          item.field_type,
                          item.field_system_attr_id
                        )
                      " ></el-input>

                      <el-select :disabled="item.is_edit=='0'"
                          v-model="tableData[scope.$index][item.field]"
                          v-if="item.type === '20'&&scope.row.isEdit"
                          style="width: 100%"
                          filterable clearable @change="getSelectFlush(item.field,scope.$index,scope.row.entry_goods_id)">
                          <template v-for="items in item.option">
                              <el-option
                              :label="items.value"
                              :value="items.value"
                              :key="items.key">
                              </el-option>
                          </template>
                      </el-select>

                       <!-- <el-input v-if="item.type=='40'&&scope.row.isEdit" v-model="tableData[scope.$index][item.field]" disabled placeholder="自动计算"></el-input> -->

                       <!-- 显示文本 -->
                      <span v-if="!scope.row.isEdit" :style="{width:item.isSuit?flexColumnWidth(item.field_text,item.field):''}">{{tableData[scope.$index][item.field]}}</span>

                  </template>

              </u-table-column>

          </u-table>

      </div>

  <!-- 批量修改 -->
  <el-dialog
    title="批量修改"
    :visible.sync="batchEditVisiable"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :before-close="closeBatchEdit">
    <div style="height: 60vh;
  overflow-x: hidden;">

    <el-form>
      <el-row :gutter="20">
        <template v-for="(item, index) in rowList" >
          <el-col :span="10" :key="index">
            <el-form-item
              :label="item.field_text"
              :label-width="formLabelWidth" v-if="item.is_batch_mod==1">

              <!-- @input="handleAttrInput($event, item.field, 1, batchEditForm, 2)" -->
              <el-input
              :disabled="item.is_edit=='0'||(item.field=='pcs'&&listParams.goods_number_type!='20')"
                v-model="batchEditForm[item.field]"
                v-if="item.type === '10' || item.type === '30'"
                :placeholder="'请输入' + item.field_text"
                style="width: 100%" 
                 @input="
                  handleAttrInput(
                    $event,
                    item.field,
                    index,
                    item,
                    2,
                    item.decimal,
                    'all',
                    '',
                    item.field_type,
                  )
                "  />

              <el-select
                v-model="batchEditForm[item.field]"
                :placeholder="'请选择' + item.field_text"
                v-if="item.type === '20'"
                style="width: 100%"
                filterable>
                <template v-for="items in item.option">
                  <el-option
                    :label="items.value"
                    :value="items.value"
                    :key="items.key">
                  </el-option>
                </template>
              </el-select>

              <el-input
                clearable
                v-model="batchEditForm[item.field]"
                placeholder="自动计算"
                :autofocus="true"
                disabled
                v-if="item.type == '40'"
              ></el-input>

            </el-form-item>
          </el-col>

        </template>
      </el-row>
       
    </el-form>
    </div>

    <div style="text-align: right; margin-top: 15px">
      <el-button type="primary" plain @click="closeBatchEdit">取消</el-button>
      <el-button type="primary" @click="handleBatchEditCommit">确定</el-button>
    </div>
  </el-dialog>

    <!-- 表格设置 -->
    <el-dialog title="设置表头" @close="closeDialogTableVisible" :visible.sync="dialogTableVisible" :close-on-click-modal ="false">
    <div class="spanBtn">
      <span @click="restore">
        恢复默认
      </span>
      <span @click="allShow('1','show')">
        全部显示
      </span>
      <span @click="allShow('0','show')">
        全部隐藏
      </span>
      <span @click="allShow('1','ice')">
        全部冻结
      </span>
      <span @click="allShow('0','ice')">
        全部解冻
      </span>
      <span @click="moveUp">
        上移
      </span>
      <span @click="moveDown">
        下移
      </span>
    </div>

    <vxe-table height="400" ref="xTable1" border resizable :data="rowList2"
    @checkbox-all="selectAllEvent" @checkbox-change="selectChangeEvent"
    :edit-config="{trigger: 'click', mode: 'cell',showIcon:false,}" :checkbox-config='{reserve: true }'
    >

      <vxe-column type="checkbox" width="60"></vxe-column>

      <vxe-column type="seq" width="60" title="序号"></vxe-column>

      <vxe-column v-for="(item,index) in tableColumn" :key="index" :title="item.title"
      :field="item.field" :type="item.type" 
      :width="item.width">

          <template #default="{ row }">

            <span v-if="item.type=='text'">{{row.field_text}}</span>

            <vxe-select  v-model="row.alignment"  v-if="item.type=='select1'">
                <vxe-option  v-for="(item,index) in options" :key="index" :value="item.value" :label="item.label">
                </vxe-option>
            </vxe-select>

            <vxe-input v-if="item.type=='input'" @blur="getInputNumber($event,row.field)" v-model="row.width" type="text"></vxe-input>

            <vxe-switch v-if="item.type=='radio1'" v-model="row.is_use" size="medium" open-value="1" close-value="0"></vxe-switch>

            <vxe-switch v-if="item.type=='radio2'" v-model="row.is_freeze" size="medium" open-value="1" close-value="0"></vxe-switch>

            <vxe-switch v-if="item.type=='radio3'" v-model="row.is_compulsory" size="medium" open-value="1" close-value="0"></vxe-switch>

          </template>

      </vxe-column>

    </vxe-table>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogTableVisible = false">
        取 消
      </el-button>
      <el-button type="primary" @click="setDispose">
        确 定
      </el-button>
    </span>
  </el-dialog>

  <!-- 弹框菜单 -->
  <div id='menus' class="menuDiv" v-show="isShowMenu">
      <div :class="index==mouseoverIndex?'mouseActive':''"  v-for="(item,index) in menuList" :key="index" @click="opeater(index)" @mouseover="mouseover(index)">{{item}}</div>
  </div>

  <LocalPrint
      :show="false"
      :printRows="printRows"
      :printData="printData"
      :title="title"
      :dialogVisible="localPrintVisible"
      @onClose="localPrintVisible = false"
      @setPrintNums="setPrintNums"
    />

  <PrintConf ref="printConf" :type="2" function_code="entryExport" :isShowBtn="false" @onRowList="handlePrintRow" />

  <el-dialog
    :visible.sync="printVisible"
    :before-close="beforeClose"
    title="打印标签"
    :destroy-on-close="true">
    <Print :type="50" :order_id="entry_id[0]" v-if="printVisible" @closeDiv="closeDiv" />
  </el-dialog>

  <el-dialog title="批量导入货品图" :visible.sync="dialogAdd" :close-on-click-modal ="false">
    <div class="chooseDiv">
      <div style="text-align:center;">
        <span style="display:inline-block;line-height:40px;margin-right:20px;">导入类型:</span>
         <el-radio style="margin-top:20px;" v-model="import_pic_type" label="1">
          按货号导入
        </el-radio>
        <el-radio style="margin-top:20px;" v-model="import_pic_type" label="2">
          按款号导入
        </el-radio>
      </div>
    </div>
    <div style="display:flex;margin:50px 0 0 200px;">
        <span style="display:inline-block;line-height:40px;margin-right:20px;">上传图片压缩包:</span>
        <el-upload
              :action="baseUrl + '/store/goods.Goods/uploadGoodsPicByZip'"
              :data="{
                import_pic_type: import_pic_type,
              }"
              :headers="{
                authorization: token,
              }"
              :show-file-list="false"
              :on-success="handleImgSuccess"
            >
              <el-button type="primary">上传<i  class="el-icon-upload el-icon--right"></i></el-button></el-upload>

    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogAdd = false">
        取 消
      </el-button>
      <el-button type="primary" @click="dialogAdd = false">
        确 定
      </el-button>
    </span>
  </el-dialog>

  <!-- 选择类型 -->
  <el-dialog title="选择货品分类" :visible.sync="dialogAdd2" :close-on-click-modal ="false">
      <choose-type ref="chooseChild" @chooseParent="chooseParent"></choose-type>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogAdd2 = false">
          取 消
        </el-button>
        <el-button type="primary" @click="childMethods">
          确 定
        </el-button>
    </span>
  </el-dialog>


     <el-dialog
      title="批量复制"
      :visible.sync="batchCopyVisiable"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :before-close="closeBatchCopy"
      width="25%"
    >
      <el-form style="margin-left: 20px" @submit.native.prevent>
        <el-form-item label="行数" label-width="80"
          ><el-input
            @keyup.enter.native="handleBatchCopyCommit"
            placeholder="请输入行数"
            v-model="copyNum"
            style="width: 50%"
        /></el-form-item>
      </el-form>
      <div style="text-align: right; margin-top: 15px">
        <el-button type="primary" plain @click="closeBatchCopy">取消</el-button>
        <el-button type="primary" @click="handleBatchCopyCommit">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
configList,
configSave,
configRestore,
createWareHouse,
getEntryGoodsList,
batchUpdateState,
getEntryInfo,
batchExportEntryGoods,
getEntryPrintReq,
getImportFile,
updateEntry,
reverseCheckEntry,
reverseEntryBill,
setPrintNums,
} from "@/api/goods/goodsWarehousing/warehousing";
import {getSetResultReq} from '@/api/system/settleConfig.js';
import Sortable from 'sortablejs';
import chooseType from './chooseType.vue'
import LocalPrint from "@/components/localPrint/Index.vue";
import PrintConf from "@/components/printConf/Index.vue";
import Print from "@/components/print/Index.vue";
import storage from "good-storage";
import Cookies from "js-cookie";
import numeral from "numeral";
export default{
  created(){
      this.loading=true;
      this.token = storage.session.get("token") || Cookies.get("token");
      this.listParams=this.$route.query
      this.getAttrList(this.$route.query)
      if(this.$route.query.istype=='detail'){
        this.entry_id.push(this.$route.query.entry_id)
        this.getEntryGoodsList(this.$route.query.entry_id)
        this.getEntryInfo(this.$route.query.entry_id)
      }
      this.clientHeight=document.documentElement.clientHeight-350;
      this.getOnoffSalePrice();
  },
  activated (){
    this.routeObj={}
    this.routeTime=this.$route.params.id
    this.routeName=this.$route.name
    this.getFlush()
  },
  deactivated () {
    this.$store.state.isFlush=false
    this.routeObj={}
  },
  components:{LocalPrint,PrintConf,Print,chooseType},
  props: ['editTableHeight','detailQuery',,'AuthpermissionsData','setPermissionsData'],
  mounted () {
      this.columnDrop()
      window.addEventListener('click',this.handleClick)
      window.addEventListener('keydown',this.f5Flush)
      var self = this
      this.$bus.$on('flushPage', function(value) {
        self.flushPage()
      })
      
  },
  updated(){
    this.$nextTick(()=>{
      this.$refs.myTable.doLayout();
    })
  },
  watch: {
  tableData:{
    handler(val,oldVal) {
      if(this.isOpen){return;}
      if (this.noWatch) {
        return;
      }
      val.forEach((row, index) => {
        this.rowList.forEach((item) => {
          if (item.type =='40') {
            // if(item.field=='2257') return;
            let m = 0;
            const cacluArray = item.caclu_field_string.split(",");
            cacluArray.forEach((data) => {
              if (row[data] || row[data] === 0 || row[data] === "0") {
                m += 1;
              }
              // if (m === cacluArray.length - 1) {
                // 公式内的所有值都存在时or等于0时才能计算
                let str='';
                let arr=item.caclu_font.split('+')
                arr.forEach((items,index)=>{
                  str+=items.slice(0,items.indexOf(']')+1)+'||0'+items.slice(items.indexOf(']')+1)+(index!=arr.length-1?'+':'')
                })
                let result
                if(item.decimal==0){
                  result= parseInt(eval(str));
                }else{
                  if(item.decimal==1){
                    result= numeral(eval(str)).format("0.0")
                  }else if(item.decimal==2){
                    result= numeral(eval(str)).format("0.00")
                  }else if(item.decimal==3){
                    result= numeral(eval(str)).format("0.000")
                  }else if(item.decimal==4){
                    result= numeral(eval(str)).format("0.0000")
                  }
                }
                const newRow = {...row, [item.field]: result||'' };
                // console.log(newRow)
                this.noWatch = true;
              
                // if(index==this.currentIndex){
                  this.$set(this.tableData, index, newRow);
                // }
                
              // }
              // else {
              //   const result = "";
              //   const newRow = { ...row, [item.field]: result };
              //   this.noWatch = true;
              //   this.$set(this.tableData, index, newRow);
              // }
            });
          }
        });
      });
    },
    immediate:true,
    deep: true,
  },
},
  data(){
      return{
          autoCaleSalePrice:1,
          routeTime:'',
          routeName:false,
          batchCopyVisiable:false,
          noWatch: false,
          dialogAdd2:false,
          good_url:'',
          good_img:'',
          baseUrl:'',
          token:'',
          import_pic_type:'1',//导入图片类型
          dialogAdd:false,
          title: "入库单",
          printRows: [],
          printData: {},
          currRow: {},
          localPrintVisible:false, //打印
          printVisible:false,      //打印标签
          dialogTableVisible:false,
          loading:false,
          batchEditForm: {}, //批量修改表单
          formLabelWidth: "150px",
          batchEditVisiable:false,//批量修改
          checked:'',
          rowList:[],
          rowList2:[],//拷贝
          tableData:[],
          menuList:[
              '复制',
              '粘贴',
              // '列宽自适应',
              // '全列宽自适应',
              '重置',
              '替换',
          ],
          isShowMenu:false,   //是否显示菜单
          mouseoverIndex:'',  //菜单角标
          currentIndex:'-1',//表格点击的横角标
          columnIndex:'', //记录竖列角标
          indexArr:[],//点击复选框的数组
          copyList:[], //复制的数据
          selectedArr:[], //选中的数据 / 批量复制的数据
          operation:'',//操作类型 add 新增  detail 详情
          orderDetail:{}, //订单详情
          entry_id:[],
          tableColumn: [ //设置表格属性
            {title: '列名', field: 'field_text',type:'text'},
            {title:'宽度',field:'width',type:'input'},
            {title:'对齐方式',field:'alignment',type:'select1',},
            {title:'显示列',field:'is_use',type:'radio1'},
            {title:'冻结列',field:'is_freeze',type:'radio2'},
            {title:'是否必填',field:'is_compulsory',type:'radio3'},
          ],
           options: [
              {value: 'left',label: '靠左对齐'},
              {value:'right',label:'靠右对齐'},
              {value:'center',label:'居中对齐',},
              {value:'default',label:'默认'}
           ],
           listParams:{}, //传递的参数
           spliceIndex:[], //角标数组
           spliceArr:[],   //上下移动的数组
           columnObj:{},    //记录列的数据
           cond:{
            page:1,
            page_num:50,
           },
           queryData:{},
           fullscreen:false, //全屏
           aloneObj:{},
           keyUpList:[],//记录输入框选中位置
           copyNum: null,
           tableDataCopy:[],// 对比的数组
           noteList:[],// 记录有变化的角标
           isFlush:'', //是否可以刷新
           routeObj:{},
           hideArr:[],
           isWatch:true,
           isTransfer:true,
           rowList3:[], //临时存储
      }
  },

  methods:{
    //获取是否自动计算标签价
    getOnoffSalePrice(){
      getSetResultReq({switch_mode:1,modules:"goods",switch_name:"auto_jisuan_saleprice"})
        .then((res) => {
          if (res.code === 1) {
           console.log("log-set",res.data);
           this.autoCaleSalePrice=res.data;
          }
        })
        .catch(() => {
        });
    },
    //修改noteList
    changeNoteList(obj,type){
      //type:1 不符合  type:2 相等
      let keys=Object.keys(obj)
        // this.noteList.push(obj)
         if(this.noteList.length==0){
            this.noteList.push(obj)
          }else{
            for(let i=0;i<this.noteList.length;i++){
              let key=Object.keys(this.noteList[i])
              if(key==keys[0]){
                  if(type==2){
                     this.noteList[i][key]=false
                  }
              }
              else{
                this.noteList.push(obj)
              }
            }
          }
          this.getFlush()
    },

    //下拉
    getSelectFlush(data,idx,id){
      //判断是否需要弹框
      let keys=idx+id+data
   
      if(this.tableDataCopy[idx]){
        if(typeof this.tableDataCopy[idx][data]=='undefined'){
          this.tableDataCopy[idx][data]=''
        }
      }
      if(this.tableData[idx][data]!=this.tableDataCopy[idx][data]){
          if(this.noteList.length>0){
            for(let i=0;i<this.noteList.length;i++){
              let key=Object.keys(this.noteList[i])
              if(key==keys){
                return;
              };
            }
          }
          this.noteList.push({[keys]:true})
      }else{
        this.noteList.forEach((item,index)=>{
          const key=Object.keys(item)
          if(key==keys){
            this.noteList[index][keys]=false
          }
        })
      }
      this.getFlush()
    },

    getFlush(){
      if(this.noteList.length>0){
        this.noteList.forEach(item=>{
          let arr= Object.values(item)
          this.isFlush= arr.every(items=>{
              return items==true
          })
        })
      }else{
        this.isFlush=false
      }
      this.routeObj={
        [this.routeTime]:this.isFlush
      }
      this.$store.state.isFlush=this.isFlush
      // this.$store.state.flushObj[this.routeTime]=this.isFlush
    },

    // 关闭标签
    flushPage(){
      if(this.routeObj[this.routeTime]){
           this.$confirm('数据发生改变, 是否保存?', '提示', {
              confirmButtonText: '保存',
              cancelButtonText: '取消',
              type: 'warning',
              distinguishCancelAndClose: true,
            }).then(() => {
              this.$parent.parentMethod(1)
              setTimeout(()=>{
                document.closetab();
              },1000)
            }).catch((action) => {
              if(action=='cancel'){
                 this.$store.state.isFlush=false
                 document.closetab();
              }
            });
        }
        // else{
        //   document.closetab();
        // }
    },

    f5Flush(event){
      // &&this.routeName=='godownEntryListDetails'
      if(event.keyCode==116&&this.routeObj[this.routeTime]&&this.routeName=='godownEntryListDetails'){
        // if(this.isFlush){
           event.preventDefault();
           this.$confirm('数据发生改变, 是否保存?', '提示', {
                confirmButtonText: '保存',
                cancelButtonText: '取消',
                distinguishCancelAndClose: true,
                type: 'warning'
              }).then(() => {
                this.$parent.parentMethod(1)
              }).catch((action) => {
                if(action=='cancel'){
                   location.reload()  
                }
              });
          }
        // }
     },

     showdialogTableVisible(){
        this.dialogTableVisible=true
        this.rowList3=JSON.parse(JSON.stringify(this.rowList2))
     },

     closeDialogTableVisible(){
        this.rowList2=this.rowList3
     },

    //表格子组件接收父组件调用方法，1保存,2保存并新增,3审核,4导入,5打印,6单据列表，7关闭
    childMethod(type,data){
        this.queryData=data
        if(type==1){
          if(this.listParams.istype=='add'){ //新增
            this.createWareHouse();
          }else if(this.orderDetail.entry_status_id==10&&this.orderDetail.is_audit==1){ //反确认编辑
            this.queryData.entry_id=this.entry_id[0]
            this.reverseCheckEntry()
          }else if(this.orderDetail.entry_status_id==10&&this.orderDetail.is_audit==0){  //正常编辑
            this.queryData.entry_id=this.entry_id[0]
            this.updateEntry()
          }
        }else if(type==2){ //保存并新增
          if(this.listParams.istype=='add'){ //新增
            this.createWareHouse(2);
          }else if(this.orderDetail.entry_status_id==10&&this.orderDetail.is_audit==1){ //反确认编辑
            this.queryData.entry_id=this.entry_id[0]
            this.reverseCheckEntry()
          }else if(this.orderDetail.entry_status_id==10&&this.orderDetail.is_audit==0){  //正常编辑
            this.queryData.entry_id=this.entry_id[0]
            this.updateEntry(false,2)
          }
        }else if(type==3){
          this.examine()
        }else if(type==4){

        }else if(type==5){
            this.printList()
        }else if(type==6){
          this.$router.push({
            name:'godownEntryList'
          })
        }
        else if(type==7){
          // this.flushPage()
          document.closetab();//调用BasicLayout页面内关闭标签页，目前只支持详情页面关闭
        }else if(type==8){
          this.batchExportEntryGoods()//导出
        }else if(type==9){  //打印设置
          this.$refs.printConf.tableVisiable=true
        }else if(type==10){ //打印标签
          this.printVisible=true
          this.$parent.getAttrList2()
        }else if(type==11){  //下载导入模板
          this.getImportFile()
        }else if(type==12){  //导入图片
        const env = process.env.NODE_ENV;
        const host = window.location.host;
        if (env === "development") {
          this.baseUrl = "http://api.dev.etouch.vip";
        } else {
        if (host === "edianbao.dev.etouch.vip") {
          this.baseUrl = "http://api.dev.etouch.vip";
        }
        if (host === "edianbao.test.etouch.vip") {
          this.baseUrl = "https://api.test.etouch.vip";
        }
        if (host === "www.etouch.top") {
          this.baseUrl = "https://api.etouch.top";
        }
        if (host === "edianbao.pretest.etouch.vip") {
          this.baseUrl = "https://api.pretest.etouch.vip";
        }
        if (host === "edianbao.test2.etouch.vip") {
          this.baseUrl = "http://api.test2.etouch.vip";
        }
      }
          this.dialogAdd=true
        }else if(type==13){ //反审核
          this.reverseEntryBill()
        }else if(type==14){ //新建
          // this.createWareHouse();
          //  this.$router.push({
          //     path: "/goods/godownEntry/Details/0",
          //     query: {
          //       metaTitle: '新增入库',
          //       goods_number_type:this.queryData.goods_type,
          //       goods_type_id:this.queryData.goods_type_id,
          //       istype:'add',
          //       t:'/goods/godownEntry/Details'
          //     }
          // });
          this.dialogAdd2=true
        }
      },


      closeDiv(){
        this.printVisible=false
      },

      //选择类型通讯
      chooseParent(){
        this.dialogAdd2=false
      },
      childMethods(){
        this.$refs.chooseChild.toDetail()
      },

      setBatch(){
        if(this.selectedArr.length<=0){
          this.$message({
            message:'请选择批量修改的数据',
            type:'warning'
          })
          return;
        }
        this.tableData3=JSON.parse(JSON.stringify(this.tableData))
        this.batchEditVisiable=true
      },


       //限制输入小数点
     allNumber(e,decimal,type) {
        let value = e.replace(/[^\-\d.]/g, '') // 只能输入.和-和数字
        value = value.replace(/^\./g, '')  //第一个字符不能是.
        value = value.replace(/\.{2,}/g, '.') // 不能连续输入.
        value = value.replace(/(\.\d+)\./g, '$1') // .后面不能再输入.
        value = value.replace(/(-)\./g, '$1') // -后面不能输入.
        value = value.replace(/\-{2,}/g, '-') // -只能保留一个
        value = value.replace(/(\d+|\.)-/g, '$1') // 数字和.后面不能接-,不能出现类似11-, 12.-
        value = value.replace(/-(0){2,}/g, "$1") // 不能出现-00,-001,-0001类似
        value = value.replace(/(-)0+(\d+)/g, '$1$2') // 不能出现-01,-02类似
        value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
        value = value.replace(/(\d{15})\d*/, '$1') // 最多保留15位整数
        let str=value.split(".")
        if(str[1]&&str[1].length>decimal){
          str[1]=str[1].substring(0,decimal)
           value=str[0]+"."+str[1]
        }
        if(type=='blur'){
           if(value){
              if(!str[1]){
                if(decimal-(str[1]?str[1].length:0)==4){
                  value=value+'.0000'
                }
                else if(decimal-(str[1]?str[1].length:0)==3){
                  value=value+'.000'
                }else if(decimal-(str[1]?str[1].length:0)==2){
                  value=value+'.00'
                }
                else if(decimal-(str[1]?str[1].length:0)==1){
                  value=value+'.0'
                }
              }else{
                if(decimal-(str[1]?str[1].length:0)==4){
                    value=value+'0000'
                  }
                  else if(decimal-(str[1]?str[1].length:0)==3){
                    value=value+'000'
                  }else if(decimal-(str[1]?str[1].length:0)==2){
                    value=value+'00'
                  }
                  else if(decimal-(str[1]?str[1].length:0)==1){
                    value=value+'0'
                  }
              }
           }
           else{
               if(decimal-(str[1]?str[1].length:0)==4){
                  value=value+'0.0000'
                }
                else if(decimal-(str[1]?str[1].length:0)==3){
                  value=value+'0.000'
                }else if(decimal-(str[1]?str[1].length:0)==2){
                  value=value+'0.00'
                }
                else if(decimal-(str[1]?str[1].length:0)==1){
                  value=value+'0.0'
                }else if(decimal-(str[1]?str[1].length:0)==0){
                  value=value+'0'
                }
           }
        }
        return value;
      },

      getBlur(event,index,filed,decimal){
        if(decimal!=0){
          let num=this.allNumber(event.target.value,parseInt(decimal),'blur')
          this.tableData[index][filed]=num;
        }
      },

      allNumber2(val){
       return val.replace(/\D/g, '')
      },

  /*
   *  获取输入框的值
   */
  handleAttrInput(event, data, index, row, type,decimal,text,id,field_type,field_system_attr_id) {
    if(field_system_attr_id=='110'){
      this.isOpen=true
    }else{
      this.isOpen=false
    }
    if(decimal!=0&&field_type!='string'){
       let num=this.allNumber(event,parseInt(decimal))
       if(text!='all'){
         this.tableData[index][data]=num;
       }else{
        this.batchEditForm[data]=num
       }
    }
    else if(decimal==0&&field_type!='string'){
      let num=this.allNumber2(event)
      if(text!='all'){
        this.tableData[index][data]=num;
      }else{
        this.batchEditForm[data]=num
      }
    }
    const list = this.tableData;
    
    //判断是否有隐藏,如果有,不参与计算
    this.hideArr.forEach((item,index)=>{
        if(item.field=="cost_price"&&item.is_auth!=0){
          this.isWatch=false
        }
    })
    if(this.isWatch && this.autoCaleSalePrice){
    if (data === "price_times") { //倍率
      if (type === 1) {
        this.tableData = list.map((item, i) => {
          // if(item.cost_price&&item.price_times&&item.sale_price){
            if (i == index) {
              if (item.cost_price) {
                item.sale_price = Number(
                  (item.price_times * item.cost_price).toFixed(decimal)
                );
              }
            }

             if(item.price_times=="Infinity"){
              item.price_times=''
            }
            return item;
          // }
        });
      } else {
        this.tableData = list.map((item, i) => {
            this.indexArr.forEach(items=>{
              if(items==i){
                  // if (item.cost_price) {
                      item.sale_price = Number(
                        (this.batchEditForm.price_times * (this.batchEditForm.cost_price?this.batchEditForm.cost_price:item.cost_price)).toFixed(2)
                      );
                  // }
              }
            })
          return item;
        });
        // if (this.batchEditForm.cost_price) {
        //   this.batchEditForm.sale_price = Number(
        //     (
        //       this.batchEditForm.price_times * this.batchEditForm.cost_price
        //     ).toFixed(2)
        //   );
        // }
      }
    }

    if (data === "cost_price") { //成本价
      if (type === 1) {
        this.tableData = list.map((item, i) => {
            // if(item.cost_price&&item.price_times&&itemsale_price){
          if (i == index) {
            if (item.price_times && !item.sale_price) {
              item.sale_price = (item.price_times * item.cost_price).toFixed(decimal);
            }
            if (item.sale_price) {
              item.price_times = Number(
                (item.sale_price / item.cost_price).toFixed(decimal)
              );
            }
          }
          if(item.price_times=="Infinity"){
            item.price_times=''
          }
          if(isNaN(item.price_times)){
            item.price_times=''
          }
          return item;
            // }
        });
      } else {
         this.tableData = list.map((item, i) => {
            this.indexArr.forEach(items=>{
                if(items==i){
                      if (item.price_times && !item.sale_price) {
                        item.sale_price = (item.price_times * this.batchEditForm.cost_price).toFixed(2);
                      }
                      if (item.sale_price||this.batchEditForm.sale_price) {
                        item.price_times = Number(
                          (item.sale_price / this.batchEditForm.cost_price).toFixed(2)
                        );
                      }
                }
            })
          return item;
        });
        // if (this.batchEditForm.price_times) {
        //   this.batchEditForm.sale_price = (
        //     this.batchEditForm.price_times * this.batchEditForm.cost_price
        //   ).toFixed(2);
        // }
      }
    }

    if (data === "sale_price") { //标签价
      if (type === 1) {
        this.tableData = list.map((item, i) => {
            // if(item.cost_price&&item.price_times&&item.sale_price){
              if (i == index) {
                if (item.cost_price) {
                  item.price_times = (item.sale_price / item.cost_price).toFixed(decimal);
                }
              }
            if(item.price_times=="Infinity"){
              item.price_times=''
            }
            if(item.price_times=='NaN'){
              item.price_times=''
            }
            return item;
            // }
        });
      } else {
         this.tableData = list.map((item, i) => {
             this.indexArr.forEach(items=>{
                if(items==i){
                      // if (item.cost_price) {
                        item.price_times = (this.batchEditForm.sale_price / (this.batchEditForm.cost_price?this.batchEditForm.cost_price:item.cost_price)).toFixed(2);
                      // }

                }
              })
          return item;
        });
        // if (this.batchEditForm.cost_price) {
        //   this.batchEditForm.price_times = (
        //     this.batchEditForm.sale_price / this.batchEditForm.cost_price
        //   ).toFixed(2);
        // }
      }
    }

    this.noWatch = false;
    // 自动计算 此处需要节流
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setTimeout(() => {}, 500);
    }

     //判断是否需要弹框
     if(type==1){

      let keys=this.currentIndex.toString()+(id||'undefined')+data
      if(this.tableDataCopy[this.currentIndex]){
        if(typeof this.tableDataCopy[this.currentIndex][data]=='undefined'){
          this.tableDataCopy[this.currentIndex][data]=''
        }
      }
      
      if(this.tableData[this.currentIndex][data]!=this.tableDataCopy[this.currentIndex][data]){
          if(this.noteList.length>0){
            for(let i=0;i<this.noteList.length;i++){
              let key=Object.keys(this.noteList[i])
              if(key==keys){
                return;
              };
            }
          }
          this.noteList.push({[keys]:true})
      }
      else{
        this.noteList.forEach((item,index)=>{
          const key=Object.keys(item)
          if(key==keys){
            this.noteList[index][keys]=false
          }
        })
      }
      this.getFlush()
     }
  },

      //记录次数
      setPrintNums(){
        setPrintNums({
          entry_id:this.entry_id[0]
        }).then(res=>{
            if(res.code==1){
              this.$parent.getEntryInfo(this.entry_id[0],2)
            }
        })

      },


      // 合计
      getSummaries({ columns, data }) {
        let that=this;
        const sums = [];
        columns.forEach((column,index)=>{
          if(index==0){
            sums[index]=`合计`
            return
          }
          if(index==1){
            sums[index]=`${this.tableData.length}行`
          }
          const values = Array.from(data).map(item => {
              return Number(item[column.property])//property为表头的prop值
          });
          if(index>=2){
            if(that.rowList[index-2].is_summary=='1'){
               sums[index] = values.reduce((prev, curr) => {
                  const value = Number(curr);//Number转换为数值
                    let sum =Number(Number(prev) + Number(curr))//.toFixed(2);//toFixed(2)数据项保留两位小数
                    if(that.rowList[index]){
                      if(parseFloat(that.rowList[index-2].decimal)!=0){
                        sum=(parseFloat(sum * 100) / 100).toFixed(parseFloat(that.rowList[index-2].decimal)||0);
                      }else if(parseFloat(that.rowList[index-2].decimal)==0){
                        sum=(parseInt(sum));
                      }
                    }
                    if (!isNaN(value)) {
                        sum=(parseFloat(sum * 100) / 100).toFixed(parseFloat(that.rowList[index-2].decimal)||0);
                        return sum;
                    } else {
                        return prev;
                    }
              }, 0);
                sums[index];
            }
          }
        })
        return [sums]
      },

      // 排序
      sortChange( {column, prop, order} ){
         if(this.listParams.istype=='add') return;
          //取消order是null
          this.cond.sort_field=column.property
          this.cond.sort_type=order=='ascending'?2:1
          this.getEntryGoodsList()
      },

        /*
        *  上传货品图片
        */
        handleImgSuccess(res, file) {
          if (res.code === 1) {
            this.$message({
              type: "success",
              message: "上传成功！",
            });
            this.good_url = res.data;
            this.good_img = URL.createObjectURL(file.raw);
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        },

       /*
        * 下载导入模板
        */
        getImportFile() {
          getImportFile({
            goods_type_id:this.queryData.goods_type_id,
            goods_number_type:this.queryData.goods_number_type, //this.goods_type,data
          }).then((res) => {
            const blob = new Blob([res], { type: "application/vnd.ms-excel" });
            const a = document.createElement("a");
            a.setAttribute("download",  `入库导入模板.xlsx`);//this.goodsTypeName+
            a.href = URL.createObjectURL(blob);
            a.click();
          });
        },

      //导入数据
      uploadData(list){
         if (this.tableData.length==0||this.tableData.length==1) {
          this.tableData = list;
          } else {
            this.tableData.push(...list);
          }
      },

      //关闭打印标签
      beforeClose() {
          this.printVisible = false;
      },

       //打印配置
      handlePrintRow(rows) {
        this.printRows = rows;
      },

      //打印
    printList(){
            this.localPrintVisible=true
            getEntryPrintReq({
              entry_id: this.entry_id[0]
            }) .then((res) => {
            if (res.code === 1) {
              this.currRow.select = "";
              res.data.list.forEach((item, index) => {
                item.index = index + 1;
              });
              res.data.top = [];
              const data = res.data.data;
              const arr1 = [
                { txt: "创建人", value: data.create_realname, width: "24%" },
                { txt: "创建时间", value: data.create_time, width: "24%" },
                { txt: "入库单号", value: data.entry_number, width: "24%" },
                { txt: "单据类型", value: data.bill_type_name, width: "24%" },
              ];
              const arr2 = [
                { txt: "门店", value: data.short_name, width: "24%" },
                { txt: "供应商", value: data.supplier_name, width: "24%" },
                { txt: "仓库", value: data.warehouse_name, width: "24%" },
                { txt: "品类", value: data.goods_type_name, width: "24%" },
              ];
              res.data.bottom = [];
              // const arr3 = [{ txt: "备注", value: data.note, width: "100%" }];
              const arr4 = [
                { txt: "审核人", value: data.audit_realname, width: "24%" },
                {
                  txt: "审核时间",
                  value: data.audit_time ? data.audit_time : "",
                  width: "30%",
                },
              ];
              res.data.top.push(arr1, arr2);
              res.data.bottom.push(arr4);//arr3,
              // 计算合计
              this.printData = res.data;
              const sum = {
                index: "合计",
              };
              const decimal = {};
              res.data.sum.index="合计";
              this.printData.list.push(res.data.sum);

            }
          })
          .catch(() => {
            this.currRow.select = "";
          });
    },


       //排序方式
      getAlign(align,field_type){
        if(field_type=='string'&&align=='default'){
          return 'left'
        }else if(field_type=='num'&&align=='default'){
          return 'right'
        }else{
          return align
        }
      },

      //详情导出
      batchExportEntryGoods(){
        if(this.listParams.istype=='add'){
          this.$message({
            type:'warning',
            message:'没有可导出的数据',
          })
          return;
        }

        batchExportEntryGoods({
          entry_id:this.entry_id[0],
        }).then((res) => {
            const blob = new Blob([res], { type: "application/vnd.ms-excel" });
            const time = new Date().getTime();
            const a = document.createElement("a");
            a.setAttribute("download", `入库单详情列表.xls`);
            a.href = URL.createObjectURL(blob);
            a.click();
        })
        .catch(() => {
        });
      },

      //保存表格配置
      setDispose(){
        let list=JSON.parse(JSON.stringify(this.rowList2));
        for(let i=0;i<list.length;i++){
          list[i].order=(1000-i).toString();
          // list[i].option=[];
        }
        configSave({
          list:[...list,...this.hideArr],
          fun:'EntryDetails',
          goods_type_id:this.listParams.goods_type_id,
          mode:'column',
        }).then(res=>{
          this.rowList=[]
          if(res.code==1){
            this.rowList=list.filter((item,index)=>{
                return item.is_use==1
            })
            this.getAttrList(this.$route.query,false)
            // this.getEntryGoodsList(this.entry_id[0])
            this.dialogTableVisible=false
            this.$forceUpdate()
             this.$message({
              message: '保存成功',
              type: 'success'
            });
          }
        })
      },

       //初始化表格配置
      restore(){
        this.$confirm('确定恢复默认?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
          configRestore({
              fun:'EntryDetails',
              goods_type_id:this.listParams.goods_type_id,
              mode:'column'
            }).then(res=>{
              if(res.code==1){
                this.rowList2=[]
                res.data.forEach((item,index)=>{
                  if(item.is_auth=='1'){
                    this.rowList2.push(item)
                  }
                })
                this.spliceIndex=[]
                this.$message({
                    message: '恢复默认成功',
                    type: 'success'
                });
              }
            })
        }).catch({});
      },

     //向上移动
      moveUp(){
        let min=Math.min(...this.spliceIndex);
        if(min===0){
          return false;
        }
        this.spliceIndex=this.spliceIndex.sort((a, b) => a - b);
        let list=JSON.parse(JSON.stringify(this.rowList2));
        for(let i=0;i<this.spliceIndex.length;i++){
          [list[this.spliceIndex[i]],list[this.spliceIndex[i]-1]]=[list[this.spliceIndex[i]-1],list[this.spliceIndex[i]]]
          this.spliceIndex[i]-=1
        }
        this.rowList2=[...list];
        let that=this;
        setTimeout(function(){//延时调用组件传递方法
            that.$refs.xTable1.scrollToRow(list[that.spliceIndex[0]]);
        },200);
      },

        //向下移动
        moveDown(){
          let max=Math.max(...this.spliceIndex);
          if(max===this.rowList2.length-1){
            return false
          }
          this.spliceIndex=this.spliceIndex.sort((a, b) => a - b)
          this.spliceIndex=this.spliceIndex.reverse()
          let list=JSON.parse(JSON.stringify(this.rowList2));
          for(let i=0;i<this.spliceIndex.length;i++){
            [list[this.spliceIndex[i]],list[this.spliceIndex[i]+1]]=[list[this.spliceIndex[i]+1],list[this.spliceIndex[i]]]
            this.spliceIndex[i]+=1
          }
          this.rowList2=[...list];
          let that=this;
          setTimeout(function(){//延时调用组件传递方法
              that.$refs.xTable1.scrollToRow(list[that.spliceIndex[0]]);
          },200);
        },

      //设置列表 全选
      selectAllEvent({ checked }){
          const records = this.$refs.xTable1.getCheckboxRecords()
          if(checked){
              records.forEach((item,index)=>{
                this.spliceIndex.push(index)
              })
              this.spliceArr=records
          }else{
            this.spliceIndex=[]
            this.spliceArr=[]
          }
      },

      selectChangeEvent({ row,rowIndex,checked }){
        if(checked){
            this.spliceIndex.push(rowIndex)
            this.spliceArr.push(row)
        }else{
            let index= this.spliceIndex.indexOf(rowIndex)
            this.spliceIndex.splice(index,1)
            this.spliceArr.splice(index,1)
        }
    },

      //全部显示/隐藏
      allShow(type,from){
        if(from=='show'){
            this.rowList2.forEach((item,index)=>{
              item.is_use=type
            })
        }else{
            this.rowList2.forEach((item,index)=>{
              item.is_freeze=type
            })
        }
      },

         //编辑入库单
        updateEntry(falg=true,type){
          if(!this.isTransfer){
            return;
          }
          this.isTransfer=false
          const entryInfo = this.queryData
          this.queryData.entry_id=this.entry_id[0]
          const newData = {
            entry_info_json: JSON.stringify({
              entryInfo,
              goodsList: this.tableData,
            }),
          };
          updateEntry(newData).then(res=>{
            if(res.code==1){
               this.isTransfer=true
               this.routeObj[this.routeTime]=false
               this.$store.state.isFlush=false
               this.tableData.forEach((item,index)=>{
                  this.$set(this.tableData[index],'isEdit',false)
               })
               this.indexArr=[]
               this.selectedArr=[]
               this.$refs.myTable.clearSelection();
               this.getEntryGoodsList()
               if(type==2){
                  document.closetab();
                  this.$router.push({
                    path: `/goods/godownEntry/Details/`+Date.now(),
                    query: {
                        metaTitle: '入库详情',
                        istype:'add',
                        t:Date.now(),
                        goods_number_type:this.queryData.goods_number_type,
                        goods_type_id:this.queryData.goods_type_id,
                      }
                  });
               }
               if(falg){
                  this.$message({
                      message: '保存成功',
                      type: 'success'
                  });
               }
               this.$bus.$emit("updateList");
            }
          }).catch((err)=>{
            this.isTransfer=true
          })
      },

        //反审核编辑
        reverseCheckEntry(falg=true){
            if(!this.isTransfer){
              return;
            }
            this.isTransfer=false
            const entryInfo = this.queryData
            this.queryData.entry_id=this.entry_id[0]
            const newData = {
              entry_info_json: JSON.stringify({
                entryInfo,
                goodsList: this.tableData,
              }),
            };
            reverseCheckEntry(newData).then(res=>{
              if(res.code==1){
                this.isTransfer=true
                this.routeObj[this.routeTime]=false
                this.$store.state.isFlush=false
                this.indexArr=[]
                this.selectedArr=[]
                this.$refs.myTable.clearSelection();
                this.getEntryGoodsList()
                if(falg){
                  this.$message({
                    type:'success',
                    message:'保存成功'
                  })
                }
                 this.$bus.$emit("updateList");
              }
            }).catch((err)=>{
            this.isTransfer=true
          })
        },

        //反审核
        reverseEntryBill(){
            reverseEntryBill({
              entry_ids:JSON.stringify(this.entry_id)
            }).then(res=>{
              if(res.code==1){
                  this.$message({
                    type:'success',
                    message:'反审核成功'
                })
                this.getEntryInfo(this.entry_id[0])
                this.$bus.$emit("updateList");
              }
            })
        },

        //审核操作
        examine(){
           if(this.routeObj[this.routeTime]){
              this.$message({
                message: '内容有修改,请保存后再审核',
                type: 'warning'
              });
              return;
            }
          //审核时,先保存再审核
          //  if(this.orderDetail.entry_status_id==10&&this.orderDetail.is_audit==1){
          //     //反审核编辑
          //    await this.reverseCheckEntry(false)
          //  }else{
          //     //编辑
          //    await this.updateEntry(false)
          //  }
          // if(this.orderDetail.entry_status_id!=10){
          //    this.$message({
          //       message: '该入库单已审核',
          //       type: 'warning'
          //     });
          //     return false;
          // }
          const entryInfo = this.queryData
          // const newData = {
          //   entry_info_json: JSON.stringify({
          //     entryInfo,
          //     goodsList: this.tableData,
          //   }),
          // };
         batchUpdateState({
            entry_ids:JSON.stringify(this.entry_id),
            entry_info_json:JSON.stringify({
              entryInfo,
              goodsList: this.tableData,
            }),
          }).then(res=>{
            if(res.code==1){
               
                this.$message({
                  type:'success',
                  message:res.msg
               })
               this.tableData.forEach((item,index)=>{
                  this.$set(this.tableData[index],'isEdit',false)
               })
               this.currentIndex='-1'
               this.getEntryInfo(this.entry_id[0])//1审核 2反审核
               this.getEntryGoodsList(this.entry_id[0])
               this.$bus.$emit("updateList");
              //  this.routeObj[this.routeTime]=false
              //  this.$store.state.isFlush=false 
            }
          })
      },


      //表格列宽变化
      changeColumnWidth(newWidth,oldWidth,column){
         this.sortableObj.destroy()
         let idx,idx2;
          this.rowList2.some((item,index)=>{
            idx=index
            return column.property==item.field
          })
          this.rowList.some((item,index)=>{
            idx2=index
            return column.property==item.field
          })
          //省略号问题
          // if(newWidth<oldWidth){
          //   this.$set(this.rowList2[idx],'isSuit',false)
          // }
          this.rowList2[idx].width=parseInt(newWidth)
          this.rowList[idx2].width=parseInt(newWidth)
          this.columnDrop()
      },

      //自适应列宽
      flexColumnWidth(label, prop){
        // 1.获取该列的所有数据
        const arr = this.tableData.map(x => x[prop])
        arr.push(label) // 把每列的表头也加进去算
        // 2.计算每列内容最大的宽度 + 表格的内间距（依据实际情况而定）
        return (this.getMaxLength(arr) + 45) + 'px'
      },

       getMaxLength (arr) {
        return arr.reduce((acc, item) => {
        if (item) {
          const calcLen = this.getTextWidth(item)
          if (acc < calcLen) {
            acc = calcLen
          }
        }
          return acc
        }, 0)
      },

      getTextWidth(str){
        let width = 0
        const html = document.createElement('span')
        html.innerText = str
        html.className = 'getTextWidth'
        document.querySelector('body').appendChild(html)
        width = document.querySelector('.getTextWidth').offsetWidth
        document.querySelector('.getTextWidth').remove()
        return width
      },

      //菜单操作
      opeater(){
        if(this.mouseoverIndex==0){ //复制
          let copyText=this.tableData[this.currentIndex][this.columnObj.property]
          this.$copyText(copyText).then(message => {
                 this.$message({
                    message: '复制成功',
                    type: 'success'
                  });
            }).catch(err => {
          })
        }else if(this.mouseoverIndex==1){//粘贴
          JSON.parse(this.copyList).forEach((item,index)=>{
             this.tableData.push(item)
          })
        }
        // else if(this.mouseoverIndex==2){ //单列宽自适应
        //   let idx;
        //   this.rowList.some((item,index)=>{
        //     idx=index
        //     return this.columnObj.label==item.field_text
        //   })
        //   this.$set(this.rowList[idx],'isSuit',true)
        // }else if(this.mouseoverIndex==3){//全列宽自适应
        //     this.rowList.forEach((item,index)=>{
        //       if(item.is_use=='1'){
        //         item.isSuit=true
        //       }
        //     })
        //     this.$forceUpdate()
        // }
        else if(this.mouseoverIndex==2){//重置
            this.tableData[this.currentIndex]={isEdit:true}
        }else if(this.mouseoverIndex==3){//替换
            let arr=JSON.parse(this.copyList)
            this.tableData.splice(this.currentIndex,1,...arr)
        }
      },
      //回车换行
      keyUp(event,data, rowindex, row,lineIndex){
          if((rowindex+1)>=this.tableData.length){
          this.tableData.push({isEdit:true,...this.aloneObj})

          }
          this.currentIndex=rowindex+1
          this.$set(this.keyUpList, 0, rowindex+1);
          this.$set(this.keyUpList, 1, lineIndex);
          let key =  'row' + (rowindex+1)+'line'+lineIndex
          this.$set(this.tableData[rowindex],'isEdit',false)
          this.$set(this.tableData[rowindex+1],'isEdit',true)
          this.$nextTick(() => {
            document.querySelector('#'+key).focus()
            document.querySelector('#'+key).select()
          })
      },

      //清空商品
      clearStore(){
        this.$confirm('确定清空商品?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.tableData=[
              {isEdit:true,...this.aloneObj}
            ]
            this.$store.state.isFlush=true
            this.routeObj[this.routeTime]=true
            this.$message({
              type: 'success',
              message: '清空成功'
            });
          }).catch(() => {});
      },

    /*
    *  获取入库单详情
    */
    getEntryInfo(entry_id) {
      getEntryInfo({ entry_id: entry_id }).then((res) => {
        if (res.code === 1) {
          this.orderDetail=res.data
          this.$parent.getChildParams(this.orderDetail)
            this.$parent.flushListMap(2,res.data.listMap,res.data.entry_status_id==20?true:false)
            this.routeObj[this.routeTime]=false
            this.$store.state.isFlush=false 
        }
      });
    },

     /*
      *  获取详情列表
      */
      getEntryGoodsList(entry_id) {
        getEntryGoodsList({
          entry_id:entry_id?entry_id:this.entry_id[0],
          ...this.cond,
          isAll:1,
        }).then((res) => {
          if (res.code === 1) {
            this.tableData=res.data.list
            this.tableDataCopy=JSON.parse(JSON.stringify(res.data.list))
            this.tableData.forEach((item,index)=>{
              item.isEdit=false
            })
          }
        });
      },

      getInputNumber(val,row){
        let idx;
        this.rowList2.some((item,index)=>{
            idx=index
            return row==item.field;
        })
        if(val.value<20){
            this.rowList2[idx].width=20
        }
      },

       //列拖动
      columnDrop() {

         this.$nextTick(()=>{
                const wrapperTr = document.querySelector('.el-table__header-wrapper tr');
                this.sortableObj = Sortable.create(wrapperTr, {
                  animation: 180,
                  delay: 0,
                  //handle: '.move', // 只有带move类名的元素才能拖动,多选框禁止拖动
                  onEnd: evt => {
                      const oldItem = this.rowList[evt.oldIndex-2]
                      this.rowList.splice(evt.oldIndex-2, 1)
                      this.rowList.splice(evt.newIndex-2, 0, oldItem)

                      let list=JSON.parse(JSON.stringify(this.rowList2))
                      if(evt.newIndex>2){
                          let field=this.rowList[evt.newIndex-2].field;  //拖动的
                          let field1=this.rowList[evt.newIndex-3].field;  //拖动的前一个
                          let index=this.rowList2.findIndex((item) => item.field== field)
                          let index1=this.rowList2.findIndex((item) => item.field== field1)
                          let item= this.rowList2.splice(index,1)
                          this.rowList2.splice(index1+1,0,item[0])
                      }else if(evt.newIndex==2){
                          let field=this.rowList[evt.newIndex-2].field;  //拖动的
                          let field1=this.rowList[evt.newIndex-2].field;  //拖动的后一个
                          let index=this.rowList2.findIndex((item) => item.field== field)
                          let index1=this.rowList2.findIndex((item) => item.field== field1)
                          let item= this.rowList2.splice(index,1)
                          this.rowList2.unshift(item[0])
                      }
                  }
                })
            })
            
      },

     // 全屏事件
    fullScreen() {
      let element = document.documentElement;
      // 判断是否已经是全屏
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        // 否则，进入全屏
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },

      //数据列表 获取全选
      tableGetAll(selection){
        this.selectedArr=selection
        //全选
        if(selection.length>0){
            this.indexArr=[]
            this.selectedArr.forEach((item,index)=>{
              this.indexArr.push(index)
            })
        }else{
          //取消全选
          this.indexArr=[]
        }
      },

        /*
        *  批量复制确定
        */
        handleBatchCopyCommit() {
          let arr=JSON.parse(JSON.stringify(this.selectedArr))
          arr[0].goods_number=''
          arr[0].entry_goods_id=''
          this.selectedArr[0].isEdit=false
          let copy=[]
          for (let i = 0; i < this.copyNum; i++) {
            // this.tableData.push({})
            copy.push(JSON.parse(JSON.stringify(arr[0])))
          }
          this.tableData=[...this.tableData,...copy]
          this.tableDataCopy=[...this.tableDataCopy,...copy]
          this.$refs.myTable.clearSelection();
          this.indexArr=[]
          if(this.tableData.length!=this.tableDataCopy){
            this.noteList.push({'keyLength':true})
          }
          else{
             this.noteList.forEach((item,index)=>{
              const key=Object.keys(item)
              if(key=='keyLength'){
                this.noteList[index]['keyLength']=false
              }
            })
          }
          this.selectedArr=[]
          this.getFlush()
          this.closeBatchCopy();
        },


       /*
        *  关闭批量复制
        */
        closeBatchCopy() {
          this.batchCopyVisiable = false;
          this.copyNum = null;
        },

      //批量复制数据
      handleBatchCopy(){
         if (this.selectedArr.length === 0) {
          this.$message.error("请勾选需复制的货品");
        } else if (this.selectedArr.length > 1) {
          this.$message.error("请勾选单个货品数据进行批量复制");
        } else {
          this.batchCopyVisiable = true;
        }
        // if(this.selectedArr.length==0){
        //    this.$message({
        //     message: '请先选择需要复制的数据',
        //     type: 'warning'
        //   });
        // }else{
        //   this.$copyText(JSON.stringify(this.selectedArr)).then(message => {
        //         this.copyList.push(message.text)
        //         this.$message({
        //           message: '复制成功',
        //           type: 'success'
        //         });
        //     }).catch(err => {
        //   })
        // }
      },

      //获取选中数据角标
      getSelect(selection,row){
        let idx=this.indexArr.indexOf(row.index)
        this.selectedArr=selection
        if(idx==-1){
          this.indexArr.push(row.index)
        }else{
          this.indexArr.splice(idx,1)
        }
      },
      
      //添加数据
      addData(){
        if(this.tableData.length>8){
          this.$refs.myTable.scrollBottom()
        }
        this.tableData.push({isEdit:true,...this.aloneObj})// this.$forceUpdate()
        this.tableDataCopy.push({...this.aloneObj,onlyId:new Date().getTime()})
        this.noteList.push({[new Date().getTime()]:true})
        this.getFlush()
      },

      //删除数据
      cutData(){
        let index;
        this.indexArr.forEach((item,idx)=>{
            if(item==this.currentIndex){
              index=idx
            }
            if(item>=this.currentIndex){
               this.indexArr[idx]=item-1
            }
        })
        this.selectedArr.splice(index,1)
        this.indexArr.splice(index,1)
        setTimeout(()=>{
          // this.indexArr.splice(1,index)
          // console.log(this.indexArr)
          this.tableData.splice(this.currentIndex,1)
          this.tableDataCopy.splice(this.currentIndex,1)
          if(!this.tableDataCopy[this.currentIndex].entry_goods_id){
            //删除新增的数据
            // console.log(this.tableDataCopy[this.currentIndex].onlyId)
            this.noteList.forEach(item=>{
               let keyArr= Object.keys(item)
               keyArr.forEach((items,index)=>{
                  items==this.tableDataCopy[this.currentIndex].onlyId
                  item[items]=false
                  // if(this.tableData.length!=this.tableDataCopy.length){
                  // }
               })
            })
            this.tableDataCopy.splice(this.currentIndex,1)
          }else{
            //删除原有数据--直接提示
            this.noteList.push({[this.currentIndex+this.tableData[this.currentIndex].entry_goods_id]:true})
          }
          this.getFlush()
        },50)
        if(this.tableData.length==1){
          this.tableData.push({isEdit:true,...this.aloneObj})
        }
      },

      //批量修改取消
      closeBatchEdit() {
          this.tableData=this.tableData3
          this.batchEditVisiable = false;
          this.batchEditForm = { ...this.filed };
      },

      //批量修改确定
      handleBatchEditCommit(event, data, index, row, type,decimal,text,id,field_type) {

        let keys=Object.keys(this.batchEditForm)
        let values=Object.values(this.batchEditForm)

        this.indexArr.forEach((item,index)=>{
          keys.forEach((itm,idx)=>{
             this.$set(this.tableData[item],itm,values[idx])
             //判断是否阻止刷新
             let objKey=index.toString()+this.tableData[index].entry_goods_id+itm
             if(this.tableDataCopy[item]){
              if(this.tableData[item][itm]!=this.tableDataCopy[item][itm]){
                  this.noteList.push({[objKey]:true})
              }else{
                  this.noteList.forEach((item,index)=>{
                      const key2=Object.keys(item)
                      if(key2==objKey){
                        this.noteList[index][objKey]=false
                      }
                    })
              }
             }
          })
        })
        this.getFlush()
        this.batchEditForm={}
        this.batchEditVisiable=false
      },


      //创建入库单
      createWareHouse(type){
          if(!this.isTransfer){
            return;
          }
          this.isTransfer=false
          let arr=this.tableData
          arr=JSON.stringify(arr)=='[{"isEdit":true,"index":0}]'?[]:this.tableData
          const entryInfo = this.queryData
          const newData = {
            entry_info_json: JSON.stringify({
              entryInfo,
              goodsList: arr,
            }),
          };
          createWareHouse(newData)
            .then((res) => {
              if (res.code === 1) {
                setTimeout(()=>{
                  this.isTransfer=true
                },2000)
                this.routeObj[this.routeTime]=false
                this.$store.state.isFlush=false
                this.$message({
                  message: '入库申请提交成功',
                  type: 'success'
                });
                  document.closetab();
                  setTimeout(()=>{
                    this.$router.push({
                      path: "/goods/godownEntry/Details/"+res.data.billid,
                      query: {
                          metaTitle: '入库详情',
                          istype:'detail',
                          entry_id:res.data.billid,
                          goods_number_type:this.queryData.goods_number_type,
                          goods_type_id:this.queryData.goods_type_id,
                          t:Date.now(),
                        }
                    });
                  },50)
                //保存并新增
                if(type==2){
                  this.tableData=[
                    {isEdit:true}
                  ]
                  setTimeout(()=>{
                      document.closetab();
                  },50)

                   this.$router.push({
                      path: `/goods/godownEntry/Details/`+Date.now(),
                      query: {
                          metaTitle: '入库详情',
                          istype:'add',
                          t:Date.now(),
                          goods_number_type:this.queryData.goods_number_type,
                          goods_type_id:this.queryData.goods_type_id,
                        }
                    });
                }
                this.$bus.$emit("updateList");
            }
          }).catch((err)=>{
            this.isTransfer=true
          })
      },

      //悬浮变色
      mouseover(idx){
          this.mouseoverIndex=idx
      },

      //监听鼠标点击事件
      handleClick(){
          //关闭菜单
          if(this.isShowMenu){
              this.isShowMenu=false
          }
      },

      //鼠标右键弹窗
      rightClick(row,column){
          this.currentIndex=row.index
          this.columnIndex=column.index
          this.columnObj=column
          event.preventDefault();
          let menu = document.querySelector("#menus");
          menu.style.left =( event.clientX-92) + "px";
          menu.style.top =( event.clientY-85)  + "px";
          menu.style.zIndex = 1000;
          this.isShowMenu=true
      },

      //设置角标
      tableRowClassName({ row,column, rowIndex,columnIndex}) {
          row.index = rowIndex;
      },

      tableCellClassName({ column,columnIndex}) {
          column.index = columnIndex;
      },
      //单击双击表格内容全选
      cellClick(row, column, cell, event){
        let that=this;
        this.$nextTick(() => {
          let key =  'row' + (that.currentIndex)+'line'+(column.index-2);
          if(document.getElementById(key)){
            document.querySelector('#'+key).select()
          }
        })
      },

      //点击选中复选框
      clickRow(row,column){
          if(this.orderDetail.entry_status_id==20){return;}
          //记录当前点击的角标
          this.currentIndex=row.index
          this.isShowMenu=false
          //将点击的表格变为可编辑
          this.tableData.forEach((item,index)=>{
            this.$set(this.tableData[index],'isEdit',false)
          })
          this.$set(this.tableData[row.index],'isEdit',true)
          this.$forceUpdate()
      },


        //获取表头
        getAttrList(obj,falg=true) {
          const data = {
            goods_type_id:obj.goods_type_id?obj.goods_type_id:this.queryData.goods_type_id,
            fun:'EntryDetails',
            is_all:1,
            mode:'column'
          };
          let arr=[]
          this.hideArr=[]
          configList(data).then((res) => {
            // this.rowList=res.data
            res.data.forEach((item,index)=>{
              item.isSuit=false
              if(item.is_use=='1'&&item.is_auth=='1'){
                if(item.field_type=='num'){
                    if(item.decimal==4){
                      // item.field=='price_times'?this.aloneObj[item.field]='1.0000':this.aloneObj[item.field]='0.0000'
                      this.aloneObj[item.field]='0.0000'
                    }else if(item.decimal==3){
                      this.aloneObj[item.field]='0.000'
                    }else if(item.decimal==2){
                      this.aloneObj[item.field]='0.00'
                    }else if(item.decimal==1){
                      this.aloneObj[item.field]='0.0'
                    }else if(item.decimal==0){
                      this.aloneObj[item.field]='0'
                    }
                }else{
                 this.aloneObj[item.field]=''
                }
                if(falg){
                  this.rowList.push(item)
                }
              }
              if(item.is_auth=='1'){
                arr.push(item)
              }else{
                //隐藏数组
                this.hideArr.push(item)
              }
            })  
            if(this.listParams.goods_number_type!=20){
              this.aloneObj['pcs']=1
            }
            //拷贝
            this.rowList2=JSON.parse(JSON.stringify(arr))
             //如果是新增,自动添加一行
            if(this.listParams.istype=='add'&&falg){
              this.tableData.push({isEdit:true,...this.aloneObj})
              this.tableDataCopy.push({...this.aloneObj})
            }
          });

        },

    }
}
</script>
<style lang="less" >
 .plTableBox .el-table__fixed-footer-wrapper tbody td{
    background: #FFF8E1 !important;
  }
  .plTableBox .el-table__footer-wrapper tbody td, .plTableBox .el-table__header-wrapper tbody td{
    background: #FFF8E1 !important;
  }
.listMyTable{
  .el-table {//解决合计缩放丢失
        overflow: visible !important;
    }
    .el-table__fixed-footer-wrapper tbody td.el-table__cell{
      background: #FFF8E1;
    }
  .has-gutter{
    .el-table__cell{
      background: #FFF8E1;
      border: 0;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #595959;
    }
  }
  .el-table{
    tr{
      height: 35px !important;
    }
    .el-table__row{
       height: 40px !important;
    }

    th{
      height: 35px !important;
      padding: 0 !important;
    }
    .el-table__header-wrapper{
       height: 35px !important;
    }
  }
}
</style>
<style lang='less' scoped>
// 表头样式
/deep/ .el-table th{
  height: 32px !important;
  background-color: rgba(242,242,242,1) !important;
  color: #595959 !important;
  font-size: 12px;
}
/deep/ .el-table th.el-table__cell>.cell{
// border-right: 1px solid rgba(216,216,216,1);
}
/deep/ .el-input__inner{
padding:0 0 0 2px !important;
}
/deep/ .el-icon-arrow-up{
position: relative;
top:4px
}
/deep/ .is-reverse{
position: relative;
top: -4px;
}
/deep/ .current-row{
background: #D4E9FD !important;
}

.headerBox{
  display: flex;
  justify-content: space-between;
  padding:15px 0;
  .opeator{
    .opeaTitle{
     font-size:16px;
     font-weight:bold;
     color:#595959;
     display:inline-block;
     margin-right:20px;
    }
  }
}
.menuDiv{
  // display: none;
  position: absolute;
  display: inline-block;
  line-height: 25px;
  letter-spacing: .5px;
  padding:10px 15px;
  width: 130px;
  color: #595959;
  background: #fff;
  border: 1px solid rgba(230,230,230,1);
  .mouseActive{
      color: #007BFF;
      cursor: pointer;
      background: rgba(233,243,253,1);
  }
}
.el-icon-full-screen{
margin-left:10px;
font-size:20px;
cursor: pointer;
display: inline-block;
}
.el-icon-setting{
font-size: 20px;
cursor: pointer;
}
/deep/ .row--hover{
  background: #D4E9FD !important;
}
.iconDiv{
.el-icon-circle-plus{
  color:#0075FF;font-size:16px;margin:0 10px;cursor: pointer;
}
.el-icon-remove{
  color:#FA5050;font-size:16px;cursor: pointer;
}
}

.spanBtn{
  display: flex;
  justify-content:flex-end;
  span{
      cursor: pointer;
      display: inline-block;
      padding:0 10px;
      margin:10px 0;
      color: #007BFF;
      border-right: 1px solid #E6E6E6;
  }
}

// /deep/ .vxe-select--panel {
//     position: fixed !important;
//     min-width: 10% !important;
//     left: auto !important;
//   }

//表格自适应
 .el-table /deep/ th {
  padding: 0;
  white-space: nowrap;
  min-width: fit-content;
}

.el-table /deep/ td {
  padding: 1px;
  white-space: nowrap;
  width: fit-content;
}

/** 修改el-card默认paddingL:20px-内边距 **/
.el-card__body {
  padding: 10px;
}

.el-table /deep/ .cell {
  white-space: nowrap;
  width: fit-content;
}

.chooseDiv{
  display: flex;justify-content:center;margin-left:-130px;
  .boxDiv{border:1px dashed #F1F1F1; margin:0 140px;
      img{width:140px;height: 120px;}
  }
}

/deep/ .gutter{
    display: none !important;
}

/deep/ ::-webkit-scrollbar{
  width:8px !important;
}
</style>
